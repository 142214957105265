import React from 'react'
import styled from 'styled-components'

import config from '../config'
import Container from './Container'
import closeIcon from '../images/x-icon-white.svg';


const Wrapper = styled.div`
  padding: 100px 70px;
  overflow-y: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1023px) {
    padding: 40px 60px;
  }
  @media (max-width: 767px) {
    display: block;
    padding: 20px;
  }
`


const Title = styled.h1`
  font-size: 70px;
  line-height: 64px;
  font-weight: bold;
  font-style: italic;
  margin: 0 0 50px 0;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 40px;
    margin-bottom: 30px;
  }

  @media (max-width: 767px) {
    font-size: 35px;
  }
`

const Content = styled.div`
  max-width: 640px;
  h2, h3, p{
    font-size: 14px;
    line-height: 1.43;
    margin:0;
  }
  h2, p{
    margin-bottom: 20px;
  }
  a{
    color: ${config.colors.black};
    &:hover{
      color: ${config.colors.red};
    }
  }
`

const Close = styled.button`
  position: fixed;
  right: 70px;
  top: 120px;
  height: 40px;
  outline: none;
  cursor: pointer;
  border: 0;
  margin: 0;
  padding: 0;
  text-decoration: none;
  background: transparent;
  background-image: url(${closeIcon});
  background-repeat: no-repeat;
  background-position: 25px center;
  background-size: 20px;
  background-color: ${config.colors.red};
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-style: italic;
  display: block;
  order: 2;
  flex: 1 0 auto;
  padding-right: 25px;
  padding-left: 54px;
    @media (max-width: 1280px) {
       top: 160px !important;
       right: 122px !important;
    }
  @media (max-width: 1024px) {
    height: 40px;
    margin-right: 0;
    margin-left: auto;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    top: 105px !important;
     right: 115px !important;
  }
  @media (max-width: 768px) {
        height: auto;
    margin-bottom: 10px;
    top: 55px !important;
    right: 35px !important;
    font-size: 14px !important;
    background-size: 14px !important;
    padding-right: 8px;
    padding-left: 26px;
    background-position: 7px center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
`


const Modal = ({ title, children, onClose }) => (
  <Wrapper>
    <Close onClick={onClose}>
      Fenster schließen
    </Close>
    <Container noPadding>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </Container>
  </Wrapper>
)

export default Modal
