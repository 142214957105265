import React from 'react'
// import styled from "styled-components";

const NewsFlashOverlay = () => (
  <>
    <h1 className={'title-news-flash'}>Anastasija Sevastova und Dominic Thiem sichern sich mit Turniertriumphen im Hangar 6 am ehemaligen Flughafen Tempelhof auch die bett1ACES-Gesamtsiege</h1>
    <p className={'news-flash-p'}>
      <strong>Berlin, 19. Juli 2020.</strong> – Mit Triumphen im zweiten Teil der Tennis-Einladungsturniere im <strong>Hangar 6</strong> des ehemaligen <strong>Flughafens Tempelhof</strong> haben sich <strong>Anastasija Sevastova</strong> und <strong>Dominic Thiem</strong> auch die Gesamtsiege bei den <strong>bett1ACES</strong> in <strong>Berlin</strong> geholt! Die Lettin und der Österreicher wurden dafür jeweils mit 50.000 Euro Preisgeld belohnt. Sevastova setzte sich am Sonntag im Damen-Finale gegen die Tschechin <strong>Petra Kvitova</strong> mit 3:6, 6:3 und 10-5 im Match-Tiebreak durch. Thiem besiegte im Endspiel den italienischen Jungstar <strong>Jannik Sinner</strong> mit 6:4, 6:2 und blieb damit bei seinem Berlin-Auftritt ungeschlagen. In der ersten Wochenhälfte hatte der Weltranglisten-Dritte Thiem schon das Rasenturnier im <strong>Steffi-Graf-Stadion</strong> des <strong>LTTC „Rot-Weiß“ e.V.</strong> für sich entschieden. 
    </p>
    <p className={'news-flash-p'}>
      „Heute habe ich mein bestes Match während der gesamten Corona-Zeit gespielt. Ich weiß nicht, was los war. Ich habe jeden Ball satt am Schläger gehabt – Aufschlag, Return. Am Ende war es überhaupt ein Wahnsinn, ich habe fast keinen Ball mehr verschlagen. Es gibt solche Tage. Und es ist schön, die Woche so abzuschließen. Wenn ich jedes Mal so spielen würde, wäre ich mehr als glücklich. Berlin war jedenfalls eine Reise wert“, freute sich Thiem. 
    </p>
    <p className={'news-flash-p'}>
      Bei den Damen krönte Anastasija Sevastova ihren Erfolgslauf im Hangar 6 nach Erfolgen über die Top-Ten-Asse <strong>Kiki Bertens</strong> und <strong>Elina Svitolina</strong> mit dem Finalsieg über <strong>Petra Kvitova</strong>. „Sie hat mit ihrem starken Aufschlag zunächst klar dominiert. Ich bin aber immer dran geblieben und habe dann im zweiten Satz auch ein Break geschafft. Im Match-Tiebreak hatte ich dann zum Glück das bessere Ende für mich. Die Verhältnisse im Hangar waren optimal für mein Spiel. Die Siege hier gegen drei starke Gegnerinnen geben mir sehr viel Selbstvertrauen“, strahlte Sevastova. 
    </p>
    <p className={'news-flash-p'}>
      Bei den Herren konnte <strong>Tommy Haas</strong>, der im Viertelfinale seinen deutschen Landsmann <strong>Jan-Lennard Struff</strong> sensationell besiegt und auch Thiem im Halbfinale voll gefordert hatte, aufgrund einer Blessur in der linken Wade zum Spiel um Platz drei gegen <strong>Roberto Bautista Agut</strong> nicht mehr antreten. Für Haas sprang der Hamburger <strong>Mischa Zverev</strong> ein, der eine starke Leistung zeigte und den Spanier mit 6:4, 6:3 bezwang. Bei den Damen sicherte sich die Darmstädterin <strong>Andrea Petkovic</strong> durch einen 6:4, 7:6 (7/1)-Erfolg über die ukrainische Weltranglisten-Fünfte <strong>Elina Svitolina</strong> Rang drei.
    </p>
    <br className={'news-flash-p'}/>
    <p className={'news-flash-p'}>
      <strong>Gesamtwertung Damen:</strong><br/>
      1. Anastasija Sevastova (60 Punkte – 50.000 € Preisgeld)<br/>
      2. Elina Svitolina (55 Punkte – 25.000 €)<br/>
      3. Petra Kvitova (50 Punkte – 15.000 €)
    </p>
    <br className={'news-flash-p'}/>
    <p className={'news-flash-p'}>
    <strong>Gesamtwertung Herren:</strong><br/>
      1. Dominic Thiem (100 Punkte – 50.000 €):<br/>
      2. Jannik Sinner (35 Punkte – 25.000 €)<br/>
      3. R. Bautista Agut und Matteo Berrettini (je 25 Punkte – je 10.000 €)
    </p>
  </>
)

export default NewsFlashOverlay
