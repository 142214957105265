import React from 'react'
import styled from 'styled-components'

import config from '../config'

import Container from './Container'
import NavigationList from './NavigationList'
import SocialMenu from './SocialMenu'


const Wrapper = styled.footer`
  background: ${config.colors.darkGray02};
  padding: 25px 0;
  @media (max-width: 700px) {
      padding: 50px 0;
    }
`

const StyledContainer = styled(Container)`
  @media (max-width: 767px) {
    display: block;
  }
`

const SocialMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .SocialMenu__Wrapper-jWHUUx{
  display: block
  }
   @media (max-width: 700px) {
      display:flex;
      flex-direction:column;
      > div {
      display:flex;
      flex-direction:column;
      }
     }
  ul{
  display: none;
  }
  a{
    margin-right: 16px;
    @media (max-width: 700px) {
    margin-right: 30px;
    width: 50px;
    height:50px;
    }
    &:last-child{
      margin-right: 0;
    }
    svg{
    @media (max-width: 700px) {
    width: 50px;
    height:50px;
    }
      g{
        fill: white;
      }
      
      width: 32px;
      height: 32px;
    }
  }
 & > div {
  display:flex;
  }
`

const Follow = styled.div`
  font-size: 20px;
  color:white;
  line-height: 24px;
  font-weight: bold;
  margin-right: 20px;
  letter-spacing: normal;
  text-transform: uppercase;
    font-style: italic;
    align-self: center;
    @media (max-width: 700px) {
    margin-right:0 ;
    margin-bottom:30px;
    font-size: 34px;
    line-height:30px;
    }
`

const NavButton = styled.div`
cursor: pointer;
  display: flex
  align-self:flex-end;
  color: white;
   font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: normal;
  text-transform: uppercase;
  font-style: italic;
  margin-right: 50px;
   @media (max-width: 1023px) {
     margin-right: 30px;
     @media (max-width: 700px) {
      margin-right: 0;
      font-size: 34px;
      line-height: 34px;
      margin-bottom:30px;
          align-self: center;
      &:last-child{
      margin-bottom:0;
      }
      &:first-child{
      margin-top:30px;
      }
        }
          }
  &:last-child{
  margin-right: 0;
  }
`

const Footer = ({ openCovid, openDatenschutz, openKontakt, openFaq }) => (
  <Wrapper>
    <StyledContainer>
      <SocialMenuWrapper>
        <div>
          <Follow>FOLGE UNS</Follow>
          <SocialMenu />
        </div>
        <div>
          <NavButton
            href="#"
            onClick={openFaq}>
            Faq
          </NavButton>
          <NavButton
            href="#"
            onClick={openCovid}>
            Covid-19
          </NavButton>
          <NavButton
            href="#"
            onClick={openDatenschutz}>
            Datenschutz
          </NavButton>
          <NavButton
            href="#"
            onClick={openKontakt}>
            Kontakt
          </NavButton>
        </div>
      </SocialMenuWrapper>

    </StyledContainer>
  </Wrapper>
)

export default Footer
