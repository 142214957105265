import React from 'react'
import styled from "styled-components";

const Faq = () => (
  <>
    <h3 className={'gra-tittle-faq'}>BESTELLUNG</h3>
    <p>
      <strong>Wie können Tickets erworben werden?</strong><br></br>
      Tickets können Sie ausschließlich über den Online Ticketshop erhalten.
    </p>
    <p>
      <strong>Wo können Stadionplan und verfügbare Tickets eingesehen werden?</strong><br></br>
      Den Stadionplan mit den verfügbaren Tickets finden Sie im Online Ticketshop.
    </p>
    <h3 className={'gra-tittle-faq'}>TICKETARTEN</h3>
    <p>
      <strong>Welche Tickets werden angeboten?</strong><br></br>
      Tageskarte - gilt für den gesamten Tag.
    </p>
    <p>
      <strong>Was ist ein print@home / Mobile Ticket?</strong><br></br>
      Dieses Ticket wird von Ihnen selbst ausgedruckt bzw. auf Ihrem Mobilen Gerät abgespeichert und muss beim <br></br>Einlass vorgezeigt werden. Andere Ticketvarianten sind nicht verfügbar.
    </p>
    <h3 className={'gra-tittle-faq'}>ERMÄSSIGUNGEN, RABATTE, PERSONEN MIT EINSCHRÄNKUNG</h3>
    <p>
      <strong>
        Wo und wie erhalte ich Rollstuhlfahrer Tickets?</strong><br></br>
      Rollstuhltickets können per E-Mail unter ticket@bett1ACES.de gebucht werden.
    </p>
    <p>
      <strong>
        Ich benötige eine Gehhilfe, darf ich diese mit auf den Centre Court nehmen?</strong><br></br>
      Krücken sind erlaubt. Rollatoren und Gehgestelle können nicht auf das Gelände mitgenommen werden. Diese<br></br> müssen am Eingang beim Taschencontainer abgegeben werden.
    </p>
    <h3 className={'gra-tittle-faq'}>ÖFFNUNGSZEITEN, ZUTRITT, ÜBERTRAGBARKEIT</h3>
    <p>
      <strong>Wann beginnen die Matches?</strong><br></br>
      Spielbeginn ist i.d.R. um 12 Uhr. (Empfehlung: Einsicht des Spielplans unter www.bett1aces.de)
    </p>
    <p>
      <strong>Dürfen Hunde mit auf den Centre Court genommen werden?</strong><br></br>
      Nein, dies ist nicht möglich.
    </p>
    <p>
      <strong>Darf ich Essen und Getränke mit auf den Center Court nehmen?</strong><br></br>
      Max. 1L Getränk (Plastikfalsche) pro Person. Essen ist erlaubt.
    </p>
    <p>
      <strong>Ist das Ticket auf den Käufer ausgestellt? Kann ich das Ticket weitergeben/verschenken?</strong><br></br>
      Unsere Tickets werden personalisiert und somit ist die Weitergabe nicht verboten.
    </p>
    <h3 className={'gra-tittle-faq'}>SPIELPLAN & SPIELER</h3>
    <p>
      <strong>Wie erhalte ich den aktuellen Spielplan?</strong><br></br>
      Der Spielplan wird jeden Abend nach Erstellung auf der Homepage veröffentlicht.
    </p>
    <p>
      <strong>Wie erfahre ich, wer am Turnier teilnimmt?</strong><br></br>
      Neue Spieler kommunizieren wir regelmäßig über unsere Kanäle (Webseite, Facebook, Instagram, Twitter)
    </p>
    <h3 className={'gra-tittle-faq'}>VERLORENE TICKETS, FALSCHE BUCHUNGEN, SCHLECHTWETTER, ETC.</h3>
    <p>
      <strong>Was passiert bei Schlechtwetter? Bekomme ich mein Geld zurück?</strong><br></br>
      Bitte beachten Sie in diesem Fall unsere AGBs auf unserer Webseite.
      <a className={'faq-link'} href="https://bett1aces.de/tickets/bestellhinweise/ " target={'_blank'}>https://bett1aces.de/tickets/bestellhinweise/ </a>
    </p>
    <p>
      <strong>Mein Favorit ist nicht im Finale, darf ich in dem Falle mein Ticket stornieren?</strong><br></br>
      Auch hier gelten unsere AGB. Umtausch & Rückgabe ausgeschlossen.
    </p>
    <p>
      <strong>Ich habe meine Tickets vergessen oder verloren. Was kann ich tun?</strong><br></br>
      Ein Einlass ohne gültiges Ticket (print@home oder mobile-Ticket) ist nicht möglich. Es können auf der Anlage keine Tickets ausgestellt werden.

    </p>
    <p>
      <strong>Was bedeutet sichtbehinderte Plätze?</strong><br></br>
      ggf. kann eine Sichteinschränkung (für Kinder oder Erwachsene mit einer Körpergröße unter 170 cm) vorliegen, durch z.B. Geländer für den Fallschutz
    </p>
    <h3 className={'gra-tittle-faq'}>ANREISE & PARKPLÄTZE</h3>
    <p>
      <strong>Wie komme ich zur Veranstaltungsstätte?</strong><br></br>
      Entsprechende Hinweise haben wir auf unserer Homepage zusammengefasst.
      <a className={'faq-link'} href="https://bett1aces.de/besucherinformationen/austragungsort/" target={'_blank'}>https://bett1aces.de/besucherinformationen/austragungsort/ </a>
    </p>
    <p>
      <strong>Wo befinden sich die Parkplätze?</strong><br></br>
      Für die Anlage im Grunewald empfehlen wir die Anfahrt mit den öffentlichen Verkehrsmitteln (BVG Ticket ist NICHT enthalten). Die S-Bahnstadion Grunewald liegt nur wenige Gehminuten entfernt. Der Weg zur Anlage ist ausgeschildert. Der Standort am Flughafen Tempelhof ist ebenfalls optimal an den öffentlichen Nahverkehr angeschlossen. Die U-Bahnstationen Platz der Luftbrücke und Paradestraße (U6) sowie die S-Bahn-Station Tempelhof liegen in fußläufiger Entfernung zum ehemaligen Flughafenareal. Mehrere Bushaltestellen sind in unmittelbarer Umgebung. Der Weg zum Hangar 6 ist ausgeschildert.
    </p>
    <p>
      <strong>Sind in meinem Ticket die öffentlichen Verkehrsmittel enthalten?</strong><br></br>
      Nein
    </p>
    <p>
      Sollten weiterhin Fragen bestehen, helfen wir Ihnen gerne persönlich weiter.
      <strong>Sind in meinem Ticket die öffentlichen Verkehrsmittel enthalten?</strong><br></br>
      Nein
    </p>
    <p>
      <strong>Bitte richten Sie allgemeine Fragen an:</strong>E-mail: <a href="info@bett1open.de">info@bett1open.de</a>
      <strong>Fragen zu Ihrem Ticket:</strong>E-mail: <a href="ticket@bett1open.de">ticket@bett1open.de</a>
    </p>
  </>
)

export default Faq
