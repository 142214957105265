import styled from 'styled-components'
import {graphql, useStaticQuery} from 'gatsby';
import * as React from 'react';
import Slider from 'react-slick';
import Container from "./Container";

const StyledContainer = styled(Container)`
    display: flex;
    flex-direction: column;
`

const nodeURL = 'https://www.instagram.com/p';

const ImageGallery = () => {
    const {
        allInstaNode: {edges},
    } = useStaticQuery(graphql`
    {
      allInstaNode(sort: { fields: timestamp, order: DESC }, limit: 9) {
        edges {
          node {
            id
            caption
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

    const renderImages = () => {
        const images = edges.map(({node}) => {
            const {
                id,
                localFile: {childImageSharp},
            } = node;
            return (
                <div className={'pt-insta-img-wrp'} key={id}>
                    <img src={childImageSharp.fluid.src}/>
                </div>
            );
        });

        return images;
    };

    const gallerySettings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 1000,
        autoplaySpeed: 10000,
        fadeIn: false,
        autoplay: false,
        pauseOnHover: false,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 700,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className={'instagram-feed'}>
            <StyledContainer>
              <span className={'gray-span insta-span'} id={'galerie'}>GALERIE</span>
                <div className={'insta-feed'}>
                    {renderImages()}
                </div>
              <a href={'https://www.instagram.com/bett1_aces/'} target={'_blank'} className="insta-button descktop-btn">Mehr auf Instagram</a>
            </StyledContainer>
            <Slider {...gallerySettings} className={'insta-feed-slider'}>
                {renderImages()}
            </Slider>
          <a href={'https://www.instagram.com/bett1_aces/'} target={'_blank'} className="insta-button mobile-btn">Mehr auf Instagram</a>
        </div>
    );
};

export default ImageGallery;

