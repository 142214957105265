import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import '../fonts/stylesheet.css'
import './layout.css'

import config from '../config'
import closeIcon from '../images/x-icon-white.svg';

const Wrapper = styled.div`
  height: 100%;
  color: ${config.colors.black};
  font-size: 14px;
  line-height: 1.57;
  font-family: 'Roboto Condensed', sans-serif;
  a{
    transition: all 0.2s ease-in-out;
  }

  .cookie-container{
    max-width: 1220px;
    margin: 0 auto;
    padding: 20px 90px 25px 30px;
    position: relative;
    .cookie-content{
      margin: 0 auto 20px auto;
      font-weight: 300;
      line-height: 1.43;
      color: #fff;
      a{
        color: #fff;
        &:hover{
          color: ${config.colors.red};
        }
      }
    }
    .cookie-button-container{
      margin: 0 auto;
    }
    .cookie-button{
      cursor: pointer;
      outline: none;
      border: none;
      color: ${config.colors.red};
      background: #fff;
      font-size: 14px;
      line-height: 40px;
      padding: 0px 44px;
      font-weight: 700;
      border-radius: 30px;
      transition: all 0.2s ease-in-out;
      &:hover{
        background: ${config.colors.red};
        color: #fff;
      }
    }
    .cookie-decline-button{
      cursor: pointer;
      outline: none;
      border: none;
      padding: 0;
      position: absolute;
      top: 20px;
      right: 30px;
      background: transparent;
      width: 20px;
      height: 20px;
      background-image: url(${closeIcon});
      background-size: 20px;
      background-repeat: no-repeat;
    }
    @media (max-width: 767px) {
      padding: 20px 50px 25px 20px;
       .cookie-decline-button{
         right: 20px;
       }
    }
  }
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content:
                'Das bett1 ACES wird vom 13. bis 19. Juli 2020 die Tenniswelt in seinen Bann ziehen! In jeweils zwei Einzelturnieren werden sechs Damen und sechs Herren im Steffi-Graf-Stadion auf Rasen sowie im Hangar am Flughafen Tempelhof auf Hartplatz wieder in den Wettkampfmodus zurückkehren.',
            },
            {
              name: 'keywords',
              content:
                'Bett1 Aces, Tennis, Berlin, STEFFI-GRAF-STADION & FLUGHAFEN TEMPELHOF, ELINA SVITOLINA, KIKI BERTENS, PETRA KVITOVA, JULIA GOERGES, DOMINIC THIEM, ALEXANDER ZVEREV, NICK KYRGIOS, JANNIK SINNER',
            },
          ]}>
          <html lang="de" />
        </Helmet>
          <Wrapper>
            {children}
          </Wrapper>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
