import React from 'react'
// import styled from "styled-components";
import covid1 from "../images/covid-1.png";
import covid2 from "../images/covid-2.png";

const ContentCovid = () => (
  <>
    <p>
      <img className={'covid-img'} src={covid1} alt="covid-1" />
    </p>

    <p>
      <img className={'covid-img'} src={covid2} alt="covid-2" />
    </p>
  </>
)

export default ContentCovid
