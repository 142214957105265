import React, {useState} from 'react'
import Slider from 'react-slick';
import styled from 'styled-components'
import Container from "./Container";


import video from '../videos/bett1aces-unboxing.mp4';
import videoSmall from '../videos/bett1aces-unboxing-small.mp4';
import videoPoster from '../videos/bett1aces-unboxing.jpg';


// import Image from 'gatsby-image';

// import zverev from "../images/player/zverev-grey@3x_.png";
// import zverevRed from "../images/player/zverev-red@3x.png";
// import zvprofile from "../images/player/zverev-profile@3x.jpg";

// import kyrgiosGrey from "../images/player/kyrgios-grey.png";
// import kyrgiosRed from "../images/player/kyrgios-red.png";
// import kyrgios from "../images/player/profile/kyrgios-profile.jpg";
// import flagAustralia from "../images/flag-australia.png";
import flagItaly from "../images/flag-italy.png";

import bertensGrey from "../images/player/bertens-gray.png";
import bertensRed from "../images/player/bertens-red.png";
import bertens from "../images/player/profile/bertens-profile.jpg";
import flagNetherlands from "../images/flag-netherlands.png";

import goergeGrey from "../images/player/goerges-gray.png";
import goergeRed from "../images/player/goerges-red.png";
import goerge from "../images/player/profile/goerges-profile.jpg";

import haasGrey from "../images/player/haas-gray.png";
import haasRed from "../images/player/haas-red.png";
import haas from "../images/player/profile/haas-profile.jpg";
import flagGermany from "../images/flag-germany.png";

import svitolinaGrey from "../images/player/svitolina-gray.png";
import svitolinaRed from "../images/player/svitolina-red.png";
import svitolina from "../images/player/profile/svitolina-profile.jpg";
import flagUkraine from "../images/flag-ukraine.png";

import sinneGrey from "../images/player/sinner-gray.png";
import sinneRed from "../images/player/sinner-red.png";
import sinne from "../images/player/profile/sinner-profile.jpg";

import thiemGrey from "../images/player/thiem-gray.png";
import thiemRed from "../images/player/thiem-red.png";
import thiem from "../images/player/profile/thiem-profile.jpg";
import flagAustria from "../images/flag-austria.png";

import kvitovaGrey from "../images/player/kvitova-gray.png";
import kvitovaRed from "../images/player/kvitova-red.png";
import kvitova from "../images/player/profile/kvitova-profile.jpg";
import flagCzech from "../images/flag-czech-republic.png";

import petkovicGrey from "../images/player/petkovic-gray.png";
import petkovicRed from "../images/player/petkovic-red.png";
import petkovic from "../images/player/profile/petkovic-profile.jpg";

import struffGrey from "../images/player/struff-gray.png";
import struffRed from "../images/player/struff-red.png";
import struff from "../images/player/profile/struff-profile.jpg";
import flagFrance from "../images/flag-france.png";

// import garciaGrey from "../images/player/garcia-gray.png";
// import garciaRed from "../images/player/garcia-red.png";
// import garcia from "../images/player/garcia-profile.jpg";

import sevastovaGrey from "../images/player/sevastova-gray.png";
import sevastovaRed from "../images/player/sevastova-red.png";
import sevastova from "../images/player/profile/sevastova-profile.jpg";
import flagLatvia from "../images/flag-latvia.png";

import khachanovGrey from "../images/player/khachanov-gray.png";
import khachanovRed from "../images/player/khachanov-red.png";
import khachanov from "../images/player/profile/khachanov-profile.jpg";
import flagRussia from "../images/flag-russia.png";

import berrettiniGrey from "../images/player/berrettini-gray.png";
import berrettiniRed from "../images/player/berrettini-red.png";
import berrettini from "../images/player/profile/berrettini-profile.jpg";

import agutGrey from "../images/player/agut-gray.png";
import agutRed from "../images/player/agut-red.png";
import agut from "../images/player/profile/agut-profile.jpg";
import flagSpain from "../images/flag-spain.png";





const Players = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 10000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: false,
    slidesToShow: 5,
    centerMode: true,
    variableWidth: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 2.5,
          centerMode: true,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          centerMode: false,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1,
          centerMode: true,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  };


  const SliderContainer2 = styled.div`
  width : 300px;
  img{
    width: 100px;
    height : auto;
  }
  `
  const ImageContainerSlider = styled.div`
  position:relative;
  h2{
  width: 160%;
  left : 5%;
  position: absolute; 
  font-size:70px;
  line-height: 64px;
  margin: 0;
  font-style: italic;
  letter-spacing: -3px;
  right: 0;
  text-transform : uppercase;
  bottom : calc( 100% + 20px );
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  transition-delay: 0s ;
      .slick-first-name{
      margin-left: 75px;
      }
  }
  `

  const StyledImage = styled.img`
    border-radius: 1rem;
    max-width: 80rem;
    margin: 0 auto;
    margin-bottom: 2rem;
  `
  const StyledContainer = styled(Container)`
`
  const [zverevBool, setZverevBool] = useState(false);
  const [kyrgiosBool, setKyrgiosBool] = useState(false);
  const [bertensBool, setBertensBool] = useState(false);
  const [haasBool, setHaasBool] = useState(false);
  const [goergeBool, setGoergeBool] = useState(false);
  const [svitolinaBool, setSvitolinaBool] = useState(false);
  const [sinneBool, setSinneBool] = useState(false);
  const [thiemBool, setThiemBool] = useState(false);
  const [kvitovaBool, setKvitovaBool] = useState(false);
  const [petkovicBool, setPetkovicBool] = useState(false);

  const [struffBool, setStruffBool] = useState(false);

  const [sevastovaBool, setSevastovaBool] = useState(false);
  const [khachanovBool, setKhachanovBool] = useState(false);
  const [berrettiniBool, setBerrettiniBool] = useState(false);
  const [agutBool, setAgutBool] = useState(false);



  return (
    <>
      <ImageContainerSlider>
        <StyledContainer>
        <span className={'gray-span players-span'} id={'players'}>SPIELER/INNEN</span>
        </StyledContainer>
        <Slider {...settings} className={'players-slider'}>
          {/* <SliderContainer2>
            <div className={'player'}>
              <StyledContainer>
                <h2><i className="slick-first-name">ALEXANDER</i><br></br><i className="slick-pt-name">ZVEREV</i></h2>
              </StyledContainer>
              <img className={'pt-normal'} src={zverev} alt={''}/>
              <img className={'pt-normal-red'} src={zverevRed} alt={''}/>
              <a className={'pt-info-pl-btn'} href="" onClick={(e) => { e.preventDefault(); return ; setZverevBool( !zverevBool ) }}><span className={'pt-int-op'}>Steckbrief anzeigen</span><span className={'pt-int-cl'}>Steckbrief schließen</span></a>
              <div className={ zverevBool ? 'profile-link pt-pl-active' : 'profile-link'}>
                <div className={'profile'}>
                  <img src={zvprofile} alt={''}/>
                  <div><p>LAND</p><h5 className={'pt-land-ico'}>DEUTSCHLAND <img src={flagGermany} alt={'flagGermany.png'} /></h5></div>
                  <div><p>ALTER</p><h5>23</h5>
                  </div>
                  <div><p>ATP RANGLISTE</p><h5>No.7</h5>
                  </div>
                  <div class="last-child"><p>TRAINER</p><h5>ALEXANDER ZVEREV SR.</h5>
                  </div>
                  <span className={'span2'}></span>
                </div>
              </div>
            </div>
          </SliderContainer2> */}
          {/* <SliderContainer2>
            <div className={'player'}>
              <StyledContainer>
                <h2><i className="slick-first-name">NICK</i><br></br><i className="slick-pt-name">KYRGIOS</i></h2>
              </StyledContainer>
              <img className={'pt-normal'} src={kyrgiosGrey} alt={''}/>
              <img className={'pt-normal-red'} src={kyrgiosRed} alt={''}/>
              <a className={'pt-info-pl-btn'} href="" onClick={(e) => { e.preventDefault(); return ; setKyrgiosBool( !kyrgiosBool ) }}><span className={'pt-int-op'}>Steckbrief anzeigen</span><span className={'pt-int-cl'}>Steckbrief schließen</span></a>
              <div className={ kyrgiosBool ? 'profile-link pt-pl-active' : 'profile-link'}>
                <div className={'profile'}>
                  <img src={kyrgios} alt={''}/>
                  <div><p>LAND</p><h5 className={'pt-land-ico'}>AUSTRALIEN
                    <img src={flagAustralia} alt={'flag-australia.png'} />
                  </h5></div>
                  <div><p>ALTER</p><h5>25</h5>
                  </div>
                  <div><p>ATP RANGLISTE</p><h5>No.40</h5>
                  </div>
                  <div class="last-child"><p>TRAINER</p><h5>keine Angabe</h5>
                  </div>
                  <span className={'span2'}></span>
                </div>
              </div>
            </div>
          </SliderContainer2> */}
          <SliderContainer2>
            <div className={'player'}>
              <StyledContainer>
                <h2><i className="slick-first-name">KIKI</i><br></br><i className="slick-pt-name">BERTENS</i></h2>
              </StyledContainer>
              <img className={'pt-normal'} src={bertensGrey} alt={''}/>
              <img className={'pt-normal-red'} src={bertensRed} alt={''}/>
              <a className={'pt-info-pl-btn'} href="" onClick={(e) => { e.preventDefault(); return ; setBertensBool( !bertensBool ) }}><span className={'pt-int-op'}>Steckbrief anzeigen</span><span className={'pt-int-cl'}>Steckbrief schließen</span></a>
              <div className={ bertensBool ? 'profile-link pt-pl-active' : 'profile-link'} >
                <div className={'profile'}>
                  <img src={bertens} alt={''}/>
                  <div><p>LAND</p><h5 className={'pt-land-ico'}>NIEDERLANDE
                    <img src={flagNetherlands} alt={'flag-australia.png'} />
                  </h5></div>
                  <div><p>ALTER</p><h5>28</h5>
                  </div>
                  <div><p>WTA RANGLISTE</p><h5>No.7</h5>
                  </div>
                  <div class="last-child"><p>TRAINER</p><h5>Elise Tamaela</h5>
                  </div>
                  <span className={'span2'}></span>
                </div>
              </div>
            </div>
          </SliderContainer2>
          <SliderContainer2>
            <div className={'player'}>
              <StyledContainer>
                <h2><i className="slick-first-name">TOMMY</i><br></br><i className="slick-pt-name">HAAS</i></h2>
              </StyledContainer>
              <img className={'pt-normal'} src={haasGrey} alt={''}/>
              <img className={'pt-normal-red'} src={haasRed} alt={''}/>
              <a className={'pt-info-pl-btn'} href="" onClick={(e) => { e.preventDefault(); return ; setHaasBool( !haasBool ) }}><span className={'pt-int-op'}>Steckbrief anzeigen</span><span className={'pt-int-cl'}>Steckbrief schließen</span></a>

              <div className={ haasBool ? 'profile-link pt-pl-active' : 'profile-link'}>
                <div className={'profile'}>
                  <img src={haas} alt={''}/>
                  <div><p>LAND</p><h5 className={'pt-land-ico'}>DEUTSCHLAND
                    <img src={flagGermany} alt={'flag-germany.png'} />
                  </h5></div>
                  <div><p>ALTER</p><h5>42</h5>
                  </div>
                  <div><p>ATP RANGLISTE</p><h5>ehem. No.2</h5>
                  </div>
                  <div class="last-child"><p>TRAINER</p><h5>keine Angabe</h5>
                  </div>
                  <span className={'span2'}></span>
                </div>
              </div>
            </div>
          </SliderContainer2>
          <SliderContainer2>
            <div className={'player'}>
              <StyledContainer>
                <h2><i className="slick-first-name">JULIA</i><br></br><i className="slick-pt-name">GÖRGES</i></h2>
              </StyledContainer>
              <img className={'pt-normal'} src={goergeGrey} alt={''}/>
              <img className={'pt-normal-red'} src={goergeRed} alt={''}/>
              <a className={'pt-info-pl-btn'} href="" onClick={(e) => { e.preventDefault(); return ; setGoergeBool( !goergeBool ) }}><span className={'pt-int-op'}>Steckbrief anzeigen</span><span className={'pt-int-cl'}>Steckbrief schließen</span></a>

              <div className={ goergeBool ? 'profile-link pt-pl-active' : 'profile-link'}>
                <div className={'profile'}>
                  <img src={goerge} alt={''}/>
                  <div><p>LAND</p><h5 className={'pt-land-ico'}>DEUTSCHLAND
                    <img src={flagGermany} alt={'flag-germany.png'} />
                  </h5></div>
                  <div><p>ALTER</p><h5>31</h5>
                  </div>
                  <div><p>WTA RANGLISTE</p><h5>No.38</h5>
                  </div>
                  <div class="last-child"><p>TRAINER</p><h5>keine Angabe</h5>
                  </div>
                  <span className={'span2'}></span>
                </div>
              </div>
            </div>
          </SliderContainer2>
          <SliderContainer2>
            <div className={'player'}>
              <StyledContainer>
                <h2><i className="slick-first-name">Jannik</i><br></br><i className="slick-pt-name">Sinner</i></h2>
              </StyledContainer>
              <img className={'pt-normal'} src={sinneGrey} alt={''}/>
              <img className={'pt-normal-red'} src={sinneRed} alt={''}/>
              <a className={'pt-info-pl-btn'} href="" onClick={(e) => { e.preventDefault(); return ; setSinneBool( !sinneBool ) }}><span className={'pt-int-op'}>Steckbrief anzeigen</span><span className={'pt-int-cl'}>Steckbrief schließen</span></a>

              <div className={ sinneBool ? 'profile-link pt-pl-active' : 'profile-link'}>
                <div className={'profile'}>
                  <img src={sinne} alt={''}/>
                  <div><p>LAND</p><h5 className={'pt-land-ico'}>Italien
                    <img src={flagItaly} alt={'flag-italy'} />
                  </h5></div>
                  <div><p>ALTER</p><h5>18</h5>
                  </div>
                  <div><p>ATP RANGLISTE</p><h5>No.73</h5>
                  </div>
                  <div class="last-child"><p>TRAINER</p><h5>Riccardo Piatti</h5>
                  </div>
                  <span className={'span2'}></span>
                </div>
              </div>
            </div>
          </SliderContainer2>
          <SliderContainer2>
            <div className={'player'}>
              <StyledContainer>
                <h2><i className="slick-first-name">ELINA</i><br></br><i className="slick-pt-name">SVITOLINA</i></h2>
              </StyledContainer>
              <img className={'pt-normal'} src={svitolinaGrey} alt={''}/>
              <img className={'pt-normal-red'} src={svitolinaRed} alt={''}/>
              <a className={'pt-info-pl-btn'} href="" onClick={(e) => { e.preventDefault(); return ; setSvitolinaBool( !svitolinaBool ) }}><span className={'pt-int-op'}>Steckbrief anzeigen</span><span className={'pt-int-cl'}>Steckbrief schließen</span></a>

              <div className={ svitolinaBool ? 'profile-link pt-pl-active' : 'profile-link'}>
                <div className={'profile'}>
                  <img src={svitolina} alt={''}/>
                  <div><p>LAND</p><h5 className={'pt-land-ico'}>UKRAINE
                    <img src={flagUkraine} alt={'flag-ukraine'} />
                  </h5></div>
                  <div><p>ALTER</p><h5>25</h5>
                  </div>
                  <div><p>WTA RANGLISTE</p><h5>No.5</h5>
                  </div>
                  <div class="last-child"><p>TRAINER</p><h5>Andrew Bettles</h5>
                  </div>
                  <span className={'span2'}></span>
                </div>
              </div>
            </div>
          </SliderContainer2>
          <SliderContainer2>
            <div className={'player'}>
              <StyledContainer>
                <h2><i className="slick-first-name">DOMINIC</i><br></br><i className="slick-pt-name">THIEM</i></h2>
              </StyledContainer>
              <img className={'pt-normal'} src={thiemGrey} alt={''}/>
              <img className={'pt-normal-red'} src={thiemRed} alt={''}/>
              <a className={'pt-info-pl-btn'} href="" onClick={(e) => { e.preventDefault(); return ; setThiemBool( !thiemBool ) }}><span className={'pt-int-op'}>Steckbrief anzeigen</span><span className={'pt-int-cl'}>Steckbrief schließen</span></a>

              <div className={ thiemBool ? 'profile-link pt-pl-active' : 'profile-link'}>
                <div className={'profile'}>
                  <img src={thiem} alt={''}/>
                  <div><p>LAND</p><h5 className={'pt-land-ico'}>ÖSTERREICH
                    <img src={flagAustria} alt={'flagAustria'} />
                  </h5></div>
                  <div><p>ALTER</p><h5>26</h5>
                  </div>
                  <div><p>ATP RANGLISTE</p><h5>No.3</h5>
                  </div>
                  <div class="last-child"><p>TRAINER</p><h5>Nicolas Massu</h5>
                  </div>
                  <span className={'span2'}></span>
                </div>
              </div>
            </div>
          </SliderContainer2>
          <SliderContainer2>
            <div className={'player'}>
              <StyledContainer>
                <h2><i className="slick-first-name">PETRA</i><br></br><i className="slick-pt-name">KVITOVA</i></h2>
              </StyledContainer>
              <img className={'pt-normal'} src={kvitovaGrey} alt={''}/>
              <img className={'pt-normal-red'} src={kvitovaRed} alt={''}/>
              <a className={'pt-info-pl-btn'} href="" onClick={(e) => { e.preventDefault(); return ; setKvitovaBool( !kvitovaBool ) }}><span className={'pt-int-op'}>Steckbrief anzeigen</span><span className={'pt-int-cl'}>Steckbrief schließen</span></a>

              <div className={ kvitovaBool ? 'profile-link pt-pl-active' : 'profile-link'}>
                <div className={'profile'}>
                  <img src={kvitova} alt={''}/>
                  <div><p>LAND</p><h5 className={'pt-land-ico'}>TSCHECHIEN
                    <img src={flagCzech} alt={'flagCzech'} />
                  </h5></div>
                  <div><p>ALTER</p><h5>30</h5>
                  </div>
                  <div><p>WTA RANGLISTE</p><h5>No.12</h5>
                  </div>
                  <div class="last-child"><p>TRAINER</p><h5>Jiri Vanek</h5>
                  </div>
                  <span className={'span2'}></span>
                </div>
              </div>
            </div>
          </SliderContainer2>
          <SliderContainer2>
            <div className={'player'}>
              <StyledContainer>
                <h2><i className="slick-first-name">ANDREA</i><br></br><i className="slick-pt-name">PETKOVIC</i></h2>
              </StyledContainer>
              <img className={'pt-normal'} src={petkovicGrey} alt={''}/>
              <img className={'pt-normal-red'} src={petkovicRed} alt={''}/>
              <a className={'pt-info-pl-btn'} href="" onClick={(e) => { e.preventDefault(); return ; setPetkovicBool( !petkovicBool ) }}><span className={'pt-int-op'}>Steckbrief anzeigen</span><span className={'pt-int-cl'}>Steckbrief schließen</span></a>

              <div className={ petkovicBool ? 'profile-link pt-pl-active' : 'profile-link'}>
                <div className={'profile'}>
                  <img src={petkovic} alt={''}/>
                  <div><p>LAND</p><h5 className={'pt-land-ico'}>DEUTSCHLAND
                    <img src={flagGermany} alt={'flagGermany'} />
                  </h5></div>
                  <div><p>ALTER</p><h5>32</h5>
                  </div>
                  <div><p>WTA RANGLISTE</p><h5>No.87</h5>
                  </div>
                  <div class="last-child"><p>TRAINER</p><h5>Dusan Vemic</h5>
                  </div>
                  <span className={'span2'}></span>
                </div>
              </div>
            </div>
          </SliderContainer2>
          <SliderContainer2>
            <div className={'player'}>
              <StyledContainer>
                <h2><i className="slick-first-name">Jan-Lennard</i><br></br><i className="slick-pt-name">Struff</i></h2>
              </StyledContainer>
              <img className={'pt-normal'} src={struffGrey} alt={''}/>
              <img className={'pt-normal-red'} src={struffRed} alt={''}/>
              <a className={'pt-info-pl-btn'} href="" onClick={(e) => { e.preventDefault(); return; setStruffBool( !struffBool )  }}><span className={'pt-int-op'}>Steckbrief anzeigen</span><span className={'pt-int-cl'}>Steckbrief schließen</span></a>

              <div className={ struffBool ? 'profile-link pt-pl-active' : 'profile-link'}>
                <div className={'profile'}>
                  <img src={struff} alt={''}/>
                  <div><p>LAND</p><h5 className={'pt-land-ico'}>DEUTSCHLAND
                    <img src={flagGermany} alt={'flagGermany'} />
                  </h5></div>
                  <div><p>ALTER</p><h5>30</h5>
                  </div>
                  <div><p>ATP RANGLISTE</p><h5>No.34</h5>
                  </div>
                  <div class="last-child"><p>TRAINER</p><h5>Carsten Arriens</h5>
                  </div>
                  <span className={'span2'}></span>
                </div>
              </div>
            </div>
          </SliderContainer2>
          {/* <SliderContainer2>
            <div className={'player'}>
              <StyledContainer>
                <h2><i className="slick-first-name">Caroline</i><br></br><i className="slick-pt-name">Garcia</i></h2>
              </StyledContainer>
              <img className={'pt-normal'} src={garciaGrey} alt={''}/>
              <img className={'pt-normal-red'} src={garciaRed} alt={''}/>
              <a className={'pt-info-pl-btn'} href="" onClick={(e) => { e.preventDefault(); return ;  }}><span className={'pt-int-op'}>Steckbrief anzeigen</span><span className={'pt-int-cl'}>Steckbrief schließen</span></a>

              <div className={ petkovicBool ? 'profile-link pt-pl-active' : 'profile-link'}>
                <div className={'profile'}>
                  <img src={garcia} alt={''}/>
                  <div><p>LAND</p><h5 className={'pt-land-ico'}>FRANCE
                    <img src={flagFrance} alt={'flagFrance'} />
                  </h5></div>
                  <div><p>ALTER</p><h5>26</h5>
                  </div>
                  <div><p>WTA RANGLISTE</p><h5>No.46</h5>
                  </div>
                  <div><p>TRAINER</p><h5>Louis Paul Garcia</h5>
                  </div>
                  <span className={'span2'}></span>
                </div>
              </div>
            </div>
          </SliderContainer2> */}


          <SliderContainer2>
            <div className={'player'}>
              <StyledContainer>
                <h2><i className="slick-first-name">Anastasija</i><br></br><i className="slick-pt-name">Sevastova</i></h2>
              </StyledContainer>
              <img className={'pt-normal'} src={sevastovaGrey} alt={''}/>
              <img className={'pt-normal-red'} src={sevastovaRed} alt={''}/>
              <a className={'pt-info-pl-btn'} href="" onClick={(e) => { e.preventDefault(); return; setSevastovaBool( !sevastovaBool ) }}><span className={'pt-int-op'}>Steckbrief anzeigen</span><span className={'pt-int-cl'}>Steckbrief schließen</span></a>


              <div className={ sevastovaBool ? 'profile-link pt-pl-active' : 'profile-link'}>
                <div className={'profile'}>
                  <img src={sevastova} alt={''}/>
                  <div><p>LAND</p><h5 className={'pt-land-ico'}>Lettland
                    <img src={flagLatvia} alt={'flagLatvia'} />
                  </h5></div>
                  <div><p>ALTER</p><h5>30</h5>
                  </div>
                  <div><p>ATP RANGLISTE</p><h5>No.43</h5>
                  </div>
                  <div class="last-child"><p>TRAINER</p><h5>Ronald Schmidt</h5>
                  </div>
                  <span className={'span2'}></span>
                </div>
              </div>
            </div>
          </SliderContainer2>


          <SliderContainer2>
            <div className={'player'}>
              <StyledContainer>
                <h2><i className="slick-first-name">Karen</i><br></br><i className="slick-pt-name">Khachanov</i></h2>
              </StyledContainer>
              <img className={'pt-normal'} src={khachanovGrey} alt={''}/>
              <img className={'pt-normal-red'} src={khachanovRed} alt={''}/>
              <a className={'pt-info-pl-btn'} href="" onClick={(e) => { e.preventDefault(); return; setKhachanovBool( !khachanovBool ) }}><span className={'pt-int-op'}>Steckbrief anzeigen</span><span className={'pt-int-cl'}>Steckbrief schließen</span></a>


              <div className={ khachanovBool ? 'profile-link pt-pl-active' : 'profile-link'}>
                <div className={'profile'}>
                  <img src={khachanov} alt={''}/>
                  <div><p>LAND</p><h5 className={'pt-land-ico'}>Russland
                    <img src={flagRussia} alt={'flagRussia'} />
                  </h5></div>
                  <div><p>ALTER</p><h5>24</h5>
                  </div>
                  <div><p>ATP RANGLISTE</p><h5>No.15</h5>
                  </div>
                  <div class="last-child"><p>TRAINER</p><h5>Fredrik Rosengren</h5>
                  </div>
                  <span className={'span2'}></span>
                </div>
              </div>
            </div>
          </SliderContainer2>


          <SliderContainer2>
            <div className={'player'}>
              <StyledContainer>
                <h2><i className="slick-first-name">Matteo</i><br></br><i className="slick-pt-name">Berrettini</i></h2>
              </StyledContainer>
              <img className={'pt-normal'} src={berrettiniGrey} alt={''}/>
              <img className={'pt-normal-red'} src={berrettiniRed} alt={''}/>
              <a className={'pt-info-pl-btn'} href="" onClick={(e) => { e.preventDefault(); return; setBerrettiniBool( !berrettiniBool ) }}><span className={'pt-int-op'}>Steckbrief anzeigen</span><span className={'pt-int-cl'}>Steckbrief schließen</span></a>


              <div className={ berrettiniBool ? 'profile-link pt-pl-active' : 'profile-link'}>
                <div className={'profile'}>
                  <img src={berrettini} alt={''}/>
                  <div><p>LAND</p><h5 className={'pt-land-ico'}>Italien
                    <img src={flagItaly} alt={'flagItaly'} />
                  </h5></div>
                  <div><p>ALTER</p><h5>24</h5>
                  </div>
                  <div><p>ATP RANGLISTE</p><h5>No.8</h5>
                  </div>
                  <div class="last-child"><p>TRAINER</p><h5>Vinczenzo Santopadre / Umberto Rianna</h5>
                  </div>
                  <span className={'span2'}></span>
                </div>
              </div>
            </div>
          </SliderContainer2>



          <SliderContainer2>
            <div className={'player'}>
              <StyledContainer>
                <h2><i className="slick-first-name">Roberto</i><br></br><i className="slick-pt-name">Bautista Agut</i></h2>
              </StyledContainer>
              <img className={'pt-normal'} src={agutGrey} alt={''}/>
              <img className={'pt-normal-red'} src={agutRed} alt={''}/>
              <a className={'pt-info-pl-btn'} href="" onClick={(e) => { e.preventDefault(); return; setAgutBool( !agutBool ) }}><span className={'pt-int-op'}>Steckbrief anzeigen</span><span className={'pt-int-cl'}>Steckbrief schließen</span></a>


              <div className={ agutBool ? 'profile-link pt-pl-active' : 'profile-link'}>
                <div className={'profile'}>
                  <img src={ agut } alt={''}/>
                  <div><p>LAND</p><h5 className={'pt-land-ico'}>Spanien
                    <img src={flagSpain} alt={'flagSpain'} />
                  </h5></div>
                  <div><p>ALTER</p><h5>32</h5>
                  </div>
                  <div><p>ATP RANGLISTE</p><h5>No.12</h5>
                  </div>
                  <div class="last-child"><p>TRAINER</p><h5>Pepe Vendrell</h5>
                  </div>
                  <span className={'span2'}></span>
                </div>
              </div>
            </div>
          </SliderContainer2>


        </Slider>
        <div className={'slider-backg'}>
        </div>
      </ImageContainerSlider>


      <StyledContainer>
        <div id="video-player-container">
          <video className={'large'} poster={videoPoster} controls>
            <source src={video}></source>
          </video>
          <video className={'small'} poster={videoPoster} controls>
            <source src={videoSmall}></source>
          </video>
        </div>
      </StyledContainer>
    </>
  )
};

export default Players;
