import React, { useState, useEffect  } from 'react'
//import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import ReactModal from 'react-modal'
import styled, { css } from 'styled-components'
import CookieConsent from 'react-cookie-consent'
import Topnav from "../components/Topnav";
import Layout from '../components/Layout'
import Header from '../components/Header'
import Content from '../components/Content'
import ContentHomepage from '../components/ContentHomepage'
import Footer from '../components/Footer'
import Modal from '../components/Modal'
import ContentDatenschutz from '../components/ContentDatenschutz'
import ContentKontakt from '../components/ContentKontakt'
import ContentCovid from '../components/ContentCovid'
import Faq from '../components/Faq'
import NewsFlashOverlay from '../components/NewsFlashOverlay'

import config from '../config'

ReactModal.setAppElement('#___gatsby')

const CookieWrapper = styled.div`
  z-index : 99999;
  background: rgba(44, 44, 44, 0.9);
  width: 100%;
  position: fixed;
  bottom: 0;
`

const appendHtml = `
<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5W5HWKR"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
`

const appendJsToHead = `
/* Google Tag Manager Container Code */
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5W5HWKR');
`

const IndexPage = () => {
    const [isOpenModalNewsFlash, setModalNewsFlashOpen] = useState(false)
    const [isOpenModalDatenschutz, setModalDatenschutzOpen] = useState(false)
    const [isOpenModalKontakt, setModalKontaktOpen] = useState(false)
    const [isOpenModalFaq, setModalFaqOpen] = useState(false)
    const [isOpenModalCovid, setModalCovidOpen] = useState(false)
    const [isScrolled, setIsScrolled] = useState(false)

    const [navbarOpen, setNavbarOpen] = useState(false)
    const [activeItem, setActiveItem] = useState('');

    useEffect(() => {
        
        var cbox = document.querySelectorAll(".pt-info-pl-btn");
        for (let i = 0; i < cbox.length; i++) {
            cbox[i].addEventListener("click", function() {
                cbox[i].classList.toggle("pt-hv-act");
            });
        }

        document.addEventListener('scroll', _ => {
            if(window.scrollY > 100)
                setIsScrolled(true);
            else
                setIsScrolled(false);
            let currentScroll = window.scrollY;
            let scrollItems = document.getElementsByClassName('nav-menu-pt');
            [].forEach.call(scrollItems, function(el) {
                el.classList.remove("active");
            });

            if( currentScroll > ( document.getElementById('partner').offsetTop - 40)){
                document.getElementById('partner-l').classList.add('active');
                setActiveItem('partner');
            }
            else  if( currentScroll > ( document.getElementById('galerie').offsetTop - 40 )){
                document.getElementById('galerie-l').classList.add('active');
                setActiveItem('galerie');
            }
            else  if( currentScroll > ( document.getElementById('turnier').offsetTop - 40)){
                document.getElementById('turnier-l').classList.add('active');
                setActiveItem('turnier');
            }
            else  if( currentScroll > ( document.getElementById('turnier').offsetTop - 40)){
                document.getElementById('turnier-l').classList.add('active');
                setActiveItem('turnier');
            }
            else  if( currentScroll > ( document.getElementById('spielorte').offsetTop - 40 )){
                document.getElementById('spielorte-l').classList.add('active');
                setActiveItem('spielorte');
            }
            else if( currentScroll > ( document.getElementById('players').offsetTop + window.outerHeight - 40 )){
                document.getElementById('players-l').classList.add('active');
                setActiveItem('players');
            }
        } )
    })

    return(
        <Layout  >

            <Helmet
                bodyAttributes={{
                    class: navbarOpen ? 'menu-open' : 'menu-close'
                }} >
                <script type="application/javascript">{`${appendJsToHead}`}</script>
            </Helmet>
            <Topnav/>
            <Header isScrolled={isScrolled} openNavBar={() => setNavbarOpen(true)} setNavbarOpen={() => setNavbarOpen(false)}
                    closeNavBar={() => setNavbarOpen(false)} activeItem={activeItem}
                    navBar = {navbarOpen}
            />
            <Content />

            <ContentHomepage 
                openNewsFlash={() => setModalNewsFlashOpen(true)}
            />
            <ReactModal
                isOpen={isOpenModalNewsFlash}
                className="modal__content"
                overlayClassName="modal__overlay"
                portalClassName="modal"
                onRequestClose={() => setModalNewsFlashOpen(false)}
                closeTimeoutMS={350}>
                <Modal
                    onClose={() => setModalNewsFlashOpen(false)}
                    title="News-Flash">
                    <NewsFlashOverlay />
                </Modal>
            </ReactModal> 

            <Footer
                openDatenschutz={() => setModalDatenschutzOpen(true)}
                openFaq={() => setModalFaqOpen(true)}
                openKontakt={() => setModalKontaktOpen(true)}
                openCovid={() => setModalCovidOpen(true)}
                 />

            <ReactModal
                isOpen={isOpenModalFaq}
                className="modal__content"
                overlayClassName="modal__overlay"
                portalClassName="modal"
                onRequestClose={() => setModalFaqOpen(false)}
                closeTimeoutMS={350}>
                <Modal
                    onClose={() => setModalFaqOpen(false)}
                    title="Faq">
                    <Faq />
                </Modal>
            </ReactModal>

            <ReactModal
                isOpen={isOpenModalDatenschutz}
                className="modal__content"
                overlayClassName="modal__overlay"
                portalClassName="modal"
                onRequestClose={() => setModalDatenschutzOpen(false)}
                closeTimeoutMS={350}>
                <Modal
                    onClose={() => setModalDatenschutzOpen(false)}
                    title="Datenschutz">
                    <ContentDatenschutz />
                </Modal>
            </ReactModal>

            <ReactModal
                isOpen={isOpenModalKontakt}
                className="modal__content"
                overlayClassName="modal__overlay"
                portalClassName="modal"
                onRequestClose={() => setModalKontaktOpen(false)}
                closeTimeoutMS={350}>
                <Modal
                    onClose={() => setModalKontaktOpen(false)}
                    title="Kontakt">
                    <ContentKontakt />
                </Modal>
            </ReactModal>

            <ReactModal
                isOpen={isOpenModalCovid}
                className="modal__content"
                overlayClassName="modal__overlay"
                portalClassName="modal"
                onRequestClose={() => setModalCovidOpen(false)}
                closeTimeoutMS={350}>
                <Modal
                    onClose={() => setModalCovidOpen(false)}
                    title="Covid-19">
                    <ContentCovid />
                </Modal>
            </ReactModal>

            <CookieWrapper>
                <CookieConsent
                    enableDeclineButton
                    declineButtonText=""
                    buttonText="Cookies zustimmen"
                    cookieName="bett1-datenschutz"
                    disableStyles
                    containerClasses="cookie-container"
                    contentClasses="cookie-content"
                    buttonWrapperClasses="cookie-button-container"
                    buttonClasses="cookie-button"
                    declineButtonClasses="cookie-decline-button"
                    expires={150}>
                    <>
                        Wir nutzen Cookies, um Ihnen den bestmöglichen Service anbieten zu können. Durch die weitere Nutzung dieser Website erklären Sie sich damit einverstanden, dass wir Cookies auf Ihrem Computer platzieren und verwenden dürfen. Weitere Informationen zu Cookies erhalten Sie in unserer{' '}
                        <a
                            href="#"
                            onClick={() => setModalDatenschutzOpen(true)}>
                            Datenschutzerklärung
                            </a>.
                    </>
                </CookieConsent>
            </CookieWrapper>
            <div
                style={{ width: 0, height: 0, overflow: 'hidden' }}
                dangerouslySetInnerHTML={{ __html: appendHtml }}
            />
        </Layout>
    )
}

export default IndexPage
