import React, {useState} from 'react'
import styled from 'styled-components'
import Ticker from 'react-ticker'
import {useStaticQuery, graphql} from "gatsby"
import PageVisibility from 'react-page-visibility'

import config from '../config'
import Slider from "react-slick/lib";


const Headerwrapper = styled.div`
  background-color: ${config.colors.darkGray02};
  position: relative;
 height: 90px;
  .ticker{
    margin:0;
    padding: 0;
    position: absolute;
    top:30px;
    bottom:30px;
    left:0;
      .ticker__element{
      display: inline-block;
      color: white;
      text-transform: uppercase;
      list-style: none;
      font-size:20px;
      line-height: 30px;
     // font-family: RobotoCondensed-Italic;
      }
    }
`

const Topnav = () => {
    const [pageIsVisible, setPageIsVisible] = useState(true);
    const handleVisibilityChange = (isVisible) => {
        setPageIsVisible(isVisible)
    }

    const {allTwitterStatusesUserTimelineGatsbyHashtag: data} = useStaticQuery(
        graphql`
      query {
        allTwitterStatusesUserTimelineGatsbyHashtag {
          edges {
            node {
              id_str
              full_text
              user {
                screen_name
                name
                profile_image_url_https 
              }
            }
          }
        }
      }
    `
    )


    return (
        <PageVisibility onChange={handleVisibilityChange}>
            {pageIsVisible && (
                <Headerwrapper>
                    <Ticker move={true} speed={10} mode="chain">
                        {({index}) => (
                            <p className={'pt-tweets-top'} style={{whiteSpace: "nowrap"}}>
                                {
                                    data.edges.map(({node}) => node).map(({full_text, id_str, user}) => (
                                            <span key={id_str}> {full_text} +++ </span>
                                        ))
                                }
                                <span>Folge uns auf <a target={'_blank'}
                                                       href={'https://www.Instagram.com/bett1_aces/'}>Instagram</a> +++ </span>
                                {
                                    data.edges.map(({node}) => node).map(({full_text, id_str, user}) => (
                                        <span key={id_str}> {full_text} +++ </span>
                                    ))
                                }
                                <span>Folge uns auf <a target={'_blank'}
                                                       href={'https://www.Instagram.com/bett1_aces/'}>Instagram</a> +++ </span>
                                {
                                    data.edges.map(({node}) => node).map(({full_text, id_str, user}) => (
                                        <span key={id_str}> {full_text} +++ </span>
                                    ))
                                }
                                <span>Folge uns auf <a target={'_blank'}
                                                       href={'https://www.Instagram.com/bett1_aces/'}>Instagram</a> +++ </span>
                              {
                                data.edges.map(({node}) => node).map(({full_text, id_str, user}) => (
                                  <span key={id_str}> {full_text} +++ </span>
                                ))
                              }
                              <span>Folge uns auf <a target={'_blank'}
                                                     href={'https://www.Instagram.com/bett1_aces/'}>Instagram</a> +++ </span>{
                              data.edges.map(({node}) => node).map(({full_text, id_str, user}) => (
                                <span key={id_str}> {full_text} +++ </span>
                              ))
                            }
                              <span>Folge uns auf <a target={'_blank'}
                                                     href={'https://www.Instagram.com/bett1_aces/'}>Instagram</a> +++ </span>{
                              data.edges.map(({node}) => node).map(({full_text, id_str, user}) => (
                                <span key={id_str}> {full_text} +++ </span>
                              ))
                            }
                              <span>Folge uns auf <a target={'_blank'}
                                                     href={'https://www.Instagram.com/bett1_aces/'}>Instagram</a> +++ </span>{
                              data.edges.map(({node}) => node).map(({full_text, id_str, user}) => (
                                <span key={id_str}> {full_text} +++ </span>
                              ))
                            }
                              <span>Folge uns auf <a target={'_blank'}
                                                     href={'https://www.Instagram.com/bett1_aces/'}>Instagram</a> +++ </span>
                            </p>
                        )}
                    </Ticker>
                </Headerwrapper>
            )}
        </PageVisibility>


    )
}

export default Topnav
