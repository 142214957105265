import React, {useState} from 'react'
import styled from 'styled-components'

import Container from './Container'
import NavigationList from './NavigationList'
import SocialMenu from './SocialMenu'
import config from '../config'
import backgroundDesktop from '../images/bett1aces_ysl_003.gif'
import backgroundMobile from '../images/bett1aces_ysl_003_mobile.gif'


let closedMenu = true;

const Wrapper = styled.div`
  background: ${config.colors.white};
  padding: 30px 0;
  transition: all 0.5s ease;
  &.fixed-header{
    transition: all 0.5s ease;
    padding: 20px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
    z-index: 9999;
    }
  svg{
  cursor:pointer;
  }
  .first-hamb{
  display:none;
   @media (max-width: 1024px) {
   display: block;
   align-self:center;
  }
  }
  .SocialMenu__Wrapper-jWHUUx{
  @media (max-width: 700px) {
  display:none;
  }
  }
`


const StyledContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 767px) {
  }
`
const OpenedResponsiveMenu = styled.div`
opacity: 0.95;
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto; 
  background: white;
  padding: 20px 20px 50px 50px;
  z-index:999;
  a{
  display: none;
  }
  li{
  display: block;
  margin-bottom: 25px;
  font-size: 34px;
  line-height: 34px;
  letter-spacing: -2px;
    @media (max-width: 1024px) {
    margin-right:0;
    &:last-child{
     margin-right:0;
     }
    }
  }
  ul{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-bottom: 39px;
  margin-top: 78px;
  @media (max-width: 1024px) {
    align-self: center;
    justify-content: center;
    align-items: center;
    }
     @media (max-width: 800px) {
    align-self: flex-start;
    justify-content: start;
    align-items: flex-start;
    }
  }
  svg{
  align-self: flex-end;
  position:fixed;
}
.SocialMenu__Wrapper-jWHUUx{
  display: flex;
  justify-content: center;
  flex-direction: column;
  & > div{
  display:flex;
}
  a{
  display:flex;
  flex-direction: row;
  font-size: 20px;
  line-height: 24pz;
  margin-right:0;
  &:last-child{
  svg{
      @media (max-width: 1024px) {
      margin-right:0px;
        }
      }
    }
  }
  ul{
    flex-direction: row;
    margin-bottom:0;
    align-self:center;
     @media (max-width: 1024px) {
    margin: 0 0 0 0px; 
      }
    li{
    margin-bottom:0;
    }
    li:first-child:after{
    font-size: 20px;
    }
  }
  svg{
  width: 50px;
  height: 50px;
  margin-bottom:50px;
  margin-right:30px;
  margin-top:0;
  position:relative;
  g{
        fill: #e3051b;
      }
  }
}
`


const Header = ({ openNavBar, closeNavBar, navBar, isScrolled, setNavbarOpen, activeItem}) => {

    return (
        <Wrapper className={isScrolled ? 'fixed-header' : ''}>

            <StyledContainer>
                <NavigationList setNavbarOpen={setNavbarOpen} activeItem={activeItem}
                                children={['TICKETS', 'SPIELER/INNEN', 'SPIELORTE', 'TURNIER', 'GALERIE', 'PARTNER']}/>
                <SocialMenu/>
                <svg className="first-hamb"
                     onClick={openNavBar} xmlns="http://www.w3.org/2000/svg" width="40" height="25" viewBox="0 0 40 25">
                    <g fill="#E3051B" fill-rule="evenodd">
                        <path d="M0 0h35c2.761 0 5 2.239 5 5H0V0zM0 10H40V15H0zM0 20h40v5H5c-2.761 0-5-2.239-5-5z"/>
                    </g>
                </svg>

                {navBar ?
                    <OpenedResponsiveMenu>
                        <svg onClick={closeNavBar} xmlns="http://www.w3.org/2000/svg" width="38" height="25"
                             viewBox="0 0 38 25">
                            <g fill="#E3051B" fill-rule="evenodd">
                                <path d="M2.93.335l30.31 17.5c2.392 1.38 3.211 4.439 1.83 6.83l-34.64-20 2.5-4.33z"/>
                                <path d="M.43 20.335l34.64-20 2.5 4.33-30.31 17.5c-2.392 1.38-5.45.561-6.83-1.83z"/>
                            </g>
                        </svg>
                        <NavigationList setNavbarOpen={setNavbarOpen} activeItem={activeItem}
                            children={['TICKETS', 'SPIELER/INNEN', 'SPIELORTE', 'TURNIER', 'GALERIE', 'PARTNER']}/>
                        <SocialMenu/>
                    </OpenedResponsiveMenu>

                    :
                    ''
                }

            </StyledContainer>
        </Wrapper>
    )
}

export default Header
