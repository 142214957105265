import React from 'react'
import styled from 'styled-components'

import config from '../config'


const Wrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
   display: none;
  }
   @media (max-width: 700px) {
      
      }
      & > div{
    display:flex;
    }
  ul{
    margin: 0 0 0 98px;
    display: flex;
    align-items: center;
    padding: 0;
     @media (max-width: 1600px) {
    margin: 0 0 0 15px; 
      }
    li{
      list-style: none;
      padding: 0 6px;
    &:first-child{
      position: relative;
      padding-left: 0;
      &:after{
      content:"/";
      position: absolute;
      right:-3px;
      top: 0px;
      line-height: 23px;
       }
     }
     a{
      font-size: 20px;
      font-weight: bold;
      font-style: italic;
      line-height: 24px;
      letter-spacing: normal;
      text-align: center;
      color: #2c2c2c;
      text-decoration: none;
      @media (max-width: 1600px) {
       font-size: 14px; 
       line-height: 24px;
        }
      }
    }
  }
`

const Link = styled.a`
  display: block;
  margin-right: 20px;
  &:last-child{
    margin-right: 0 !important;
  }
  svg{
   width: 30px;
    g{
      transition: all 0.2s ease-in-out;
    }
  }
  &:hover{
    svg{
      g{
         fill: ${config.colors.red};
      }
    }
  }
`


const SocialMenu = () => (
  <Wrapper className={'SocialMenu__Wrapper-jWHUUx'}>
    <div>
    <Link
      href="https://www.instagram.com/bett1_aces/"
      target="_blank"
      rel="noopener noreferrer">
      <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30"
           viewBox="0 0 31 30">
        <g fill="#2C2C2C" fillRule={"evenodd"}>
          <path
            d="M15 1C8.935 1 3.56 4.906 1.686 10.673c-1.874 5.768.178 12.088 5.085 15.652 4.908 3.567 11.55 3.567 16.458 0 4.907-3.564 6.959-9.884 5.085-15.652C26.44 4.906 21.065 1 15 1m14.265 9.364c2.008 6.181-.192 12.952-5.45 16.77-5.255 3.821-12.375 3.821-17.63 0-5.258-3.818-7.458-10.589-5.45-16.77C2.743 4.184 8.503 0 15 0c6.497 0 12.257 4.184 14.265 10.364"
            transform="translate(.333)"/>
          <path
            d="M19.926 19.295c0 .348-.284.631-.63.631h-8.593c-.346 0-.63-.283-.63-.63v-5.198h1.236c-.072.289-.108.591-.108.903 0 2.099 1.7 3.798 3.798 3.798 2.1 0 3.8-1.7 3.8-3.798 0-.312-.038-.614-.108-.903h1.235v5.197zm-7.23-5.197c.36-.92 1.256-1.573 2.303-1.573 1.048 0 1.944.653 2.304 1.573.11.279.171.583.171.903 0 1.364-1.109 2.474-2.475 2.474-1.364 0-2.474-1.11-2.474-2.474 0-.32.06-.624.17-.903zm4.98-3.625c0-.193.156-.349.35-.349h1.508c.192 0 .349.156.349.35v1.51c0 .191-.157.347-.35.347h-1.508c-.193 0-.349-.156-.349-.348v-1.51zm1.62-1.723h-8.593c-1.078 0-1.953.876-1.953 1.955v8.59c0 1.08.875 1.954 1.953 1.954h8.592c1.079 0 1.955-.874 1.955-1.954v-8.59c0-1.079-.876-1.955-1.955-1.955z"
            transform="translate(.333)"/>
        </g>
      </svg>
    </Link>
    <Link
      href="https://twitter.com/bett1aces"
      target="_blank"
      rel="noopener noreferrer">
      <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30"
           viewBox="0 0 31 30">
        <g fill="#2C2C2C" fillRule={"evenodd"}>
          <path
            d="M15 1C8.935 1 3.56 4.906 1.686 10.673c-1.874 5.768.178 12.088 5.085 15.652 4.908 3.567 11.55 3.567 16.458 0 4.907-3.564 6.959-9.884 5.085-15.652C26.44 4.906 21.065 1 15 1m14.265 9.364c2.008 6.181-.192 12.952-5.45 16.77-5.255 3.821-12.375 3.821-17.63 0-5.258-3.818-7.458-10.589-5.45-16.77C2.743 4.184 8.503 0 15 0c6.497 0 12.257 4.184 14.265 10.364"
            transform="translate(.333)"/>
          <path
            d="M12.049 18.532c-1.602-.057-2.602-1.234-2.867-2.19.445.087.884.07 1.32-.04.012-.004.023-.01.045-.021-.868-.208-1.538-.682-2.002-1.45-.309-.509-.456-1.065-.45-1.677.431.237.885.37 1.37.382-.652-.48-1.09-1.11-1.269-1.915-.177-.802-.062-1.564.332-2.284 1.668 2.011 3.78 3.115 6.356 3.297-.017-.126-.037-.235-.05-.346-.085-.743.06-1.434.45-2.063.491-.788 1.191-1.278 2.09-1.427 1.038-.174 1.942.129 2.7.88.05.051.092.066.162.051.634-.142 1.232-.376 1.793-.707L22.07 9 22.09 9c-.242.734-.687 1.3-1.32 1.718.597-.068 1.168-.23 1.718-.474l.012.015c-.118.157-.232.32-.354.474-.328.412-.703.775-1.12 1.09-.036.026-.054.052-.052.1.017.468 0 .935-.057 1.4-.118.981-.386 1.916-.795 2.81-.421.923-.972 1.755-1.665 2.487-1.174 1.243-2.589 2.046-4.236 2.419-.565.126-1.138.19-1.716.208-1.777.053-3.42-.4-4.932-1.356l-.073-.047c1.076.118 2.1-.023 3.083-.435.523-.221 1.007-.508 1.466-.876"
            transform="translate(.333)"/>
        </g>
      </svg>
    </Link>
    <Link
      href="https://www.facebook.com/bett1ACESTennisturnier"
      target="_blank"
      rel="noopener noreferrer">
      <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30"
           viewBox="0 0 31 30">
        <g fill="#2C2C2C" fillRule={"evenodd"}>
          <path
            d="M15 1C8.935 1 3.56 4.906 1.686 10.673c-1.874 5.768.178 12.088 5.085 15.652 4.908 3.567 11.55 3.567 16.458 0 4.907-3.564 6.959-9.884 5.085-15.652C26.44 4.906 21.065 1 15 1m14.265 9.364c2.008 6.181-.192 12.952-5.45 16.77-5.255 3.821-12.375 3.821-17.63 0-5.258-3.818-7.458-10.589-5.45-16.77C2.743 4.184 8.503 0 15 0c6.497 0 12.257 4.184 14.265 10.364"
            transform="translate(.333)"/>
          <path
            d="M17.73 10.032c.481-.017.963-.004 1.446-.004h.199V7.61c-.257-.025-.526-.06-.796-.073-.494-.022-.99-.045-1.484-.035-.756.016-1.471.193-2.097.625-.716.497-1.109 1.195-1.259 2.018-.062.338-.078.691-.087 1.037-.013.543-.002 1.083-.002 1.626v.205h-2.4v2.7h2.386V22.5h2.916v-6.776h2.378l.365-2.713c-.191 0-.362-.001-.534 0-.676.003-2.228 0-2.228 0s.006-1.339.022-1.919c.024-.794.513-1.037 1.174-1.06"
            transform="translate(.333)"/>
        </g>
      </svg>
    </Link>
    </div>
    <ul>
      <li className={'lang-actv'}>
        <Link
          href="/"
          rel="noopener noreferrer">
          DE
        </Link>
      </li>
      <li>
        <Link
          href="/en"
          rel="noopener noreferrer">
          EN
        </Link>
      </li>
    </ul>
  </Wrapper>
)

export default SocialMenu
