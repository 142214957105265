import React, {useState} from 'react';
import Slider from 'react-slick';
import styled from 'styled-components'
import Container from "./Container";
import SocialMenu from "./SocialMenu";
// import Image from 'gatsby-image';

import merkel from "../images/merkel.jpg";


const Turnir = () => {
  const settinggs = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 1000,
    autoplaySpeed: 10000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: false,
    slidesToShow: 2,
    centerMode: false,
    // variableWidth: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };


  const StyledImage = styled.img`
    border-radius: 1rem;
    max-width: 80rem;
    margin: 0 auto;
    margin-bottom: 2rem;
  `
  const StyledContainer = styled(Container)`
  .homepage-pop-up{
      bottom: 90px;
      left: 40px;
      margin: 0;
      padding: 30px;
      position:absolute;
      border-radius: 1px;
      ybox-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
      .SocialMenu__Wrapper-jWHUUx{
      margin: 0;
      ul{
      display:none;
      }
    }
    h5{
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: bold;
    font-style: italic;
    line-height: 1;
    letter-spacing: normal;
    color: #e3051b;
    }
    p{
    font-size: 16px;
    font-weight: normal;
    font-style: italic;
    line-height: 1.25;
    letter-spacing: normal;
    color: #2c2c2c;
    }
    .span2{
    border-top: 35px solid #ffffff;
    background-color: transparent !important;
    border-right: 35px solid transparent;
    position: absolute;
    bottom: -34px;
    left: 25px;
    margin:0;
    padding:0;
    width: auto;
    }
  }
`

  const [turnirOpen, setTurnirOpen] = useState(false);

  return (
    <div className={'gifs'}>
      <StyledContainer>
        <div className={'wrapper-content'}>
          <span className={'gray-span'} id={'turnier'}>TURNIER</span>


          <div className={'merkel-greetings'}>
            <img src={merkel} alt="merkel" />
            <div className={'text'}>
              <p>"Auch im Spitzentennis bedeutet die Corona-Pandemie eine schwierige Ausnahmesituation. Als Schirmherrin bedauere ich zwar, dass die diesjährigen Grass Court Championships Berlin nicht stattfinden können. Umso mehr freue ich mich aber über die <span class="bold">bett1ACES</span> als attraktive Ersatzturniere.</p> 
              <p>Den Spielerinnen und Spielern wünsche ich eine glückliche Hand und allen Tennis-Freunden, die die Wettkämpfe am Bildschirm verfolgen, spannende Unterhaltung vom ersten Aufschlag bis zum letzten Matchball."</p>
              <p class="bold"><span class="bold">Angela Merkel, Bundeskanzlerin</span></p>
            </div>
          </div>


          <Slider {...settinggs} className={'npm-slick'}>
            <div>
              <div className={'one-gif-content'}>
                <h2>DAMEN </h2>
                <div className={'content-gifs'}>
                  <div className={'women-men-gif'}>
                    <span className={'red-span'}>TAG1 – VIERTELFINALE</span>
                  </div>
                  <div className={'women-men-gif'}>
                    <span className={'red-span'}>TAG 2 – HALBFINALE</span>
                  </div>
                  <div className={'women-men-gif'}>
                    <span className={'red-span'}>TAG 3 – FINALE</span>
                  </div>
                </div>
                <div className={'white-bg women'}>
                </div>
              </div>
            </div>
            <div>
              <div className={'one-gif-content'}>
                <h2>HERREN</h2>
                <div className={'content-gifs'}>
                  <div className={'women-men-gif'}>
                    <span className={'red-span'}>TAG1 – VIERTELFINALE</span>
                  </div>
                  <div className={'women-men-gif'}>
                    <span className={'red-span'}>TAG 2 – HALBFINALE</span>
                  </div>
                  <div className={'women-men-gif'}>
                    <span className={'red-span'}>TAG 3 – FINALE</span>
                  </div>
                </div>
                <div className={'white-bg men'}>
                </div>
              </div>
            </div>
          </Slider>


          <div className={'button-with-hover'}>
            <a className={'gif-button'} onClick={ (e) => { e.preventDefault(); setTurnirOpen( !turnirOpen )  } }>
              { !turnirOpen ? 'Mehr erfahren' : 'Fenster schließen' }
            </a>
            {turnirOpen ?
            <div className={'homepage-pop-up'}>
              <SocialMenu/>
              <h5>
                TURNIERINFOS
              </h5>
              <p>„Weltklasse Tennis, Top SpielerInnen, Rasen- und Hartplatz an zwei atemberaubenden Locations in Berlin“</p>

                <p><strong>Locations | Zeitraum | Belag:</strong></p>
              <p>BERLIN – Steffi-Graf-Stadion | 13. – 15. Juli 2020 | Rasen<br></br>
                 BERLIN – Tempelhof Hangar | 17. – 19. Juli 2020 | Hartplatz</p>
              <div className={'inner-div'}>
                <p><strong>Spieler/Innen:<br></br>Spielformat:</strong></p>
                <p>Jeweils 6 Spielerinnen pro Events<br></br>2 gesetzte SpielerInnen</p>
              </div>
              <div className={'inner-div'}>
                <p><strong>Electronic Features:</strong></p>
                <p>Innovatives TV-Übertragungskonzept durch <br></br>
                  TV Drohnen, 360° LED & Electronic Line Calling</p>
              </div>
              <span className={'span2'}></span>
            </div>
             : ''}
          </div>

        </div>
      </StyledContainer>
    </div>
  )
};

export default Turnir;
