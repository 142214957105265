import styled, { css } from 'styled-components'

const Container = styled.div`
  width: 100%;
  max-width: 1460px; 
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1600px) {
    max-width: 1140px; 
  }
   @media (max-width: 1220px) {
   max-width: 900px; 
  }
  @media (max-width: 1024px) {
  max-width: 668px; 
  }
  @media (max-width: 768px) {
   max-width: 668px; 
  }
   @media (max-width: 700px) {
   max-width: 100%; 
   padding:0 20px;
  }
 

  ${props =>
    props.noPadding &&
    css`
      padding-left: 0 !important;
      padding-right: 0 !important;
    `}
`

export default Container
