import React from 'react'
import styled from "styled-components";

const ContentKontakt = () => (
  <>
    <p>
      <strong>e|motion sports GmbH Germany</strong> <br />
      <strong>Office Berlin</strong> <br />
      Kurfürstendamm 32, 10719 Berlin <br />
      Telefon: +49 30 5770 5270 0 <br />
      E-mail: <a href="mailto:info@bett1open.de">info@bett1open.de</a>
    </p>

    <p>
      <strong>e|motion sports GmbH Germany </strong><br />
      <strong>office stuttgart</strong> <br />
      Feuerbacher weg 99, 70192 Stuttgart <br />
      Telefon: +49 (0)711 16543 61 <br />
      E-mail: <a href="mailto:info@mercedescup.de">info@mercedescup.de</a> <br />
      CEO | Founder: Edwin Weindorfer
    </p>

    <h3><strong>Haftungshinweis</strong></h3>
    <p>
    Trotz sorgfaltiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
    </p>

    <h3><strong>Copyright</strong></h3>
    <p>
    Die gesamten auf dieser Website publizierten Informationen und Gestaltungselemente dürfen ohne schriftliche Genehmigung des Verfassers weder gespeichert noch kopiert oder vervielfältigt werden. Es gelten die üblichen Copyright-Bestimmungen.
    </p>
  </>
)

export default ContentKontakt
