import styled, { css } from 'styled-components'

const Break = styled.br`
  ${props =>
    props.desktop &&
    css`
      @media (max-width: 860px) {
        display: none;
      }
    `}

  ${props =>
    props.mobile &&
    css`
      @media (min-width: 860px) {
        display: none;
      }
    `}
`

export default Break
