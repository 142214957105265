import React from 'react'
import styled from 'styled-components'

import Container from './Container'
import Title from './Title'
import SubTitle from './SubTitle'
import Break from  './Break'
import config from '../config'
import backgroundDesktop from '../images/bett1aces_campaign_1920x1080_02.jpg'
import backgroundDesktopVideo from '../videos/bett1ACES-1920x890-v2.mp4'
import backgroundDesktop1440Video from '../videos/bett1ACES-1440x730-v2.mp4'
import backgroundDesktop768Video from '../videos/bett1ACES-768x854-v2.mp4'
import backgroundDesktop375Video from '../videos/bett1ACES-375x537-v2.mp4'


const StyledContainer = styled(Container)`
  background: #fff;
  padding-bottom: 120px;
  @media (max-width: 767px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`

const Wrapper = styled.div`
  background-image: url(${backgroundDesktop});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 730px;
`

const Content = () => (
  <Wrapper className={'pt-video-cnt'}>
    <video playsInline className={'myVideo myVideo-1900'} width="320" height="240" autoPlay muted loop>
      <source src={backgroundDesktopVideo} type="video/mp4" />
    </video>
    <video playsInline className={'myVideo myVideo-1400'} width="320" height="240" autoPlay muted loop>
      <source src={backgroundDesktop1440Video} type="video/mp4" />
    </video>
    <video playsInline className={'myVideo myVideo-700'} width="320" height="240" autoPlay muted loop>
      <source src={backgroundDesktop768Video} type="video/mp4" />
    </video>
    <video playsInline className={'myVideo myVideo-300'} width="320" height="240" autoPlay muted loop>
      <source src={backgroundDesktop375Video} type="video/mp4" />
    </video>
  </Wrapper>

)
export default Content
