import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
// import ReactModal from 'react-modal'
// import Modal from '../components/Modal'
import config from '../config'
import backgroundDesktop from "../images/background-1440-px.svg";
import backgroundMobile from "../images/ffffffff.jpg";
import imageT from "../images/bett1aces_locations_1080x1080_steffigrafstadion-sm.gif";
import image from "../images/bett1aces_locations_1080x1080_tempelhof-sm.gif";
import Container from "./Container";
import Players from "./Players";
import SocialMenu from "./SocialMenu";
import Turnir from "./Turnir";
import Partners from "./Partners";
import ImageGallery from '../components/ImageGallery'

import trophySmall from "../images/final-trophy-small.jpg";
import trophyLarge from "../images/final-trophy-large.jpg";



const Wrapper = styled.div`
  background-image: url(${backgroundDesktop});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100%;
  @media (max-width: 1440px) {
    /* background-image: url(${backgroundMobile});*/  
  }
  @media (max-width: 768px){
    background-image: none !important;
    background-color: #ececec !important;
}
`
const WhiteText = styled.div`
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
  margin-left: 20px;
  transform: translateY(-100px);
  background-color: white;
  padding: 2.5em 3em;
  display: inline-block;
  position:relative;
  z-index: 10;

  @media (max-width: 1220px) {
    padding: 2.5em 2.6em 2em 2.5em;
    transform: translateY(-57px);
  }
  @media (max-width: 1024px) {
    padding: 2.5em 2.1em 2em 2em;
    transform: translateY(-90px);
  }
  @media (max-width: 700px) {
    padding: 2.5em 1.6em 2em 1.5em;
    transform: translateY(-94px);
    margin-left:10px;
  }
  @media (max-width: 420px) {
    margin-left:0px;
    padding:  2.2em 1.3em 1.4em 1.2em;
  }
  @media (max-width: 360px) {
    margin-left:0px;
    padding:  2em 1.1em 1.2em 1em;
  }

  h1{
    font-size: 3.5rem;
    line-height: 1;
    margin: 0;
    display: inline;
    font-style: italic;
    letter-spacing: -0.042em;

    strong{
      margin-left: 1.15em;
    }

    @media (max-width: 700px) {
      font-size: 2.6rem;
    }
    @media (max-width: 500px) {
      font-size: 2.1rem;
    }
    @media (max-width: 420px) {
      font-size: 1.8rem;
    }
    @media (max-width: 360px) {
      font-size: 1.6rem;
    }

    @media (max-width: 320px) {
      font-size: 1.5rem;
    }
  }


  span{
    background-color: ${config.colors.darkGray02};
    color:white;
    font-size: 16px;
    line-height:20px;
    letter-spacing: normal;
    font-weight: bold;
    font-style: italic;
    padding: 8px 10px 8px 10px;
    position: absolute;
    top: -20px;
    left: -20px;
  }
`
const StyledContainer = styled(Container)`
`
const ColSection = styled.div`
  justify-content:space-between;
  display: flex;
  
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  img{
    width: 100%;
    height:520px;
     @media (max-width: 1024px) {
       margin-right: 0 !important; 
       width: 520px;
      }
      @media (max-width: 700px) {
      width:335px;
      height:316px;
      align-self: center;
      }
    }
 .wp-something{
    display:flex;
    flex-direction: column;
    margin-right: 180px;
    width: 520px;
    margin-bottom: 100px;
    margin-left: 120px;
    .second-button{
    display: flex;
    align-self: flex-start;
    margin-bottom: 110px;
    height: 40px;
    @media (max-width: 1024px) {
    display:none;
    }
    @media (max-width: 1340px) {
      .homepage-pop-up {
           left: 0;
         }
       }
    }
    .first-button{
    display: flex;
    align-self: flex-end;
    height: 40px;
    .span2{
    margin-bottom: 0;
    }
    @media (max-width: 1240px) {
      .homepage-pop-up {
           left: 0;
         }
       }
       @media (max-width: 880px) {
      .homepage-pop-up {
           left: auto;
           right: 0;
         }
         .span2{
             left: auto;
          right: 15px;
         border-left: 35px solid transparent;
         border-right: none;
         }
       }
       @media (max-width: 700px) {
      .homepage-pop-up {
           left: 0;
           right: auto;
         }
         .span2{
          right: auto;
          left: 15px;
         border-right: 35px solid transparent;
         border-left: none;
         }
       }
    @media (max-width: 700px) {
        align-self: flex-start;
       }
    }
    @media (max-width: 1600px) {
      margin-left: 0 !important; 
      margin-right: 100px;
     }
    @media (max-width: 1024px) {
     margin-right: 0 !important; 
     width: 100%
    }
      &:first-child{
        span{
         @media (max-width: 1024px) {
          margin-bottom:50px;
          }
        }
        .button-red{
        &.hidden-link{
        margin-bottom: 0;
        @media (max-width: 700px) {
          align-self:flex-start;
          font-size: 16px; 
          line-height:20px;
          }
        }
       }
      }
      &:last-child{
      margin-right: 120px;
      margin-left: 0 !important;
      @media (max-width: 1600px) {
         margin-right: 0 !important;
         margin-left: 0 !important; 
        }
        img{
        margin-bottom: 0;
        @media (max-width: 1024px) {
         order: 1;
         align-self: flex-end;
        }
        @media (max-width: 700px) {
            align-self: center;
            }
       }
      h2{
        text-align: left;
        margin-top: 166px;
         @media (max-width: 1024px) {
         order: 2;
         margin-top: 50px;
        }
       }
        .button-red{
        align-self: flex-start;
        margin-bottom: 110px;
         @media (max-width: 1024px) {
         order: 3;
         @media (max-width: 700px) {
          align-self: flex-end;
          font-size: 16px; 
          line-height:20px;
          }
        }
      }
    }
  }
    .gray-sp{
      background-color: #575756;
      color: white;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: normal;
      font-weight: bold;
      font-style: italic;
      padding: 8px 10px 8px 10px;
      width: 97px;
      margin-bottom: 80px;
      text-transform: uppercase;
      transform: translateX(-120px);
      @media (max-width: 1600px) {
        transform: translateX(0);
        }
    }
    img{
      margin-bottom: 140px;
      @media (max-width: 1024px) {
      margin-bottom: 50px;
        }
        @media (max-width: 700px) {
      margin-bottom: 30px;
      width: 100%;
      height: auto
        }
    }
    h2{
      margin-bottom: 30px;
      margin-top: 0;
      font-size: 70px;
      font-weight: bold;
      letter-spacing: -3px;
      color: #2c2c2c;
      line-height: 64px;
      text-align: right;
        @media (max-width: 1024px) {
      display: none;
    }
    @media (max-width: 700px) {
    font-size: 34px;
    line-height:34px;
    }
      &.hidden-tittle{
      display: none;
       @media (max-width: 1024px) {
        display: block;
        }
      }
    i{
    margin-right:0;
    @media (max-width: 700px) {
          margin-right: 30px;
          margin-left: 0;
        }
      &.i-class{
      margin-right: 57px;
      @media (max-width: 1024px) {
        margin-right: 0;
        margin-left: 50px;
        }
         @media (max-width: 700px) {
        margin-right: 0;
        margin-left: 25px;
        }
      }
      &.steffi-i{
      margin-left: 85px;
       @media (max-width: 1024px) {
          margin-right: 40px;
          margin-left: 0;
        }
        @media (max-width: 700px) {
          margin-right: 0;
        }
      }
    }
  }
    .button-red{
    font-size: 16px;
    background-color: #e3051b;
     font-weight: bold;
     font-style: italic;
     line-height: 20px;
     letter-spacing: normal;
     color: white;
     width: 220px;
     height: 40px;
     text-decoration: none;
     display: flex;
     justify-content: center;
     align-items: center;
     align-self: flex-end;
     text-align: center;
       svg{
       margin-right:10px;
       }
    @media (max-width: 1024px) {
      display: none;
      }
    &.hidden-link{
    display: none;
     @media (max-width: 1024px) {
      display: flex;
        }
      }
    }
  }
  .button-with-hover{
     display: flex;
     justify-content: center;
     align-self: flex-end;
       &.pt-order{
           justify-content: center;
           display: flex;
           height:40px;
           align-self: flex-start;
           .homepage-pop-up {
           left: 0;
           }
            @media (max-width: 700px) {
            .homepage-pop-up {
                 left: auto;
                 right: 0;
               }
               .span2{
               left: auto;
               right: 15px;
               border-left: 35px solid transparent;
               border-right: none;
               }
             }
           a{
           margin-bottom: 0 !important; 
           }
       @media (max-width: 1024px) {
           order: 3;
           }
           @media (max-width: 700px) {
            align-self: flex-end;
           }
       }
     }
  .homepage-pop-up{
      z-index: 999;
      margin: 0;
      padding: 30px;
      position:absolute;
      border-radius: 1px;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
      &.pt-mobile{
          display :none;
        }
      @media (max-width: 1024px) {
        display:none;
        &.pt-mobile{
          display :block;
        }
      }
      
      .SocialMenu__Wrapper-jWHUUx{
      margin: 0;
      ul{
      display:none;
      }
       @media (max-width: 1024px){
       display: block;
       }
    }
    h5{
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: bold;
    font-style: italic;
    line-height: 1;
    letter-spacing: normal;
    color: #e3051b;
    }
    p{
    font-size: 16px;
    font-weight: normal;
    font-style: italic;
    line-height: 1.25;
    letter-spacing: normal;
    color: #2c2c2c;
    }
    .span2{
    border-top: 35px solid #ffffff;
    background-color: transparent !important;
    border-right: 35px solid transparent;
    position: absolute;
    bottom: -34px;
    left: 25px;
    margin:0;
    padding:0;
    width: auto;
    }
  }
`


const ContentHomepage = ({openNewsFlash}) => {

  const [steffiGrafStadionOpen, setSteffiGrafStadionOpen] = useState(false)
  const [flughagenOpen, setFlughagenOpen] = useState(false)
  

  useEffect(() => {
      var cbtn = document.querySelectorAll(".button-red");
      for (let i = 0; i < cbtn.length; i++) {
        cbtn[i].addEventListener("click", function () {
            cbtn[i].classList.toggle("pt-bv-act");
        });
      }
  }, []);
  return (
    <Wrapper>
      <StyledContainer>
        <WhiteText>
          <span className={'gray-sp'}>SENDEZEIT</span>
          <h1>
            TÄGLICH LIVE AUF<br></br>
            <strong>EUROSPORT</strong> & SERVUS TV
          </h1>
        </WhiteText>
      </StyledContainer>
      
      
      <StyledContainer>
        <div className={'news-flash time-table'}>
          <span className={'gray-span'}>Newsflash</span>
          <a onClick={openNewsFlash}>
            <div class="message-red-bold">
              Anastasija Sevastova und Dominic Thiem sichern sich auch Gesamtsieg 
            </div>
            <img className={'news-image'} srcset={trophySmall + ' 1000w,' + trophyLarge + ' 1500w' }
                sizes="100vw"
                src={trophySmall}
                alt="Anastasija Sevastova und Dominic Thiem sichern sich auch Gesamtsieg "></img>
          </a>


        </div>
      </StyledContainer>
      

      <StyledContainer>
        <div className={'time-table'}>
          <span className={'gray-span'}>SPIELZEITEN 19. JULI 2020</span>
          {/* <div class="message-red-bold">
            ALLE SPIELE WEGEN REGEN auf 18:30 Uhr VERSCHOBEN
          </div> */}

          <ul>
            <li>
              <span className={'special-hint'}>3.Platz Herren</span>
              <span className={'red'}>12 Uhr (CEST) </span><br className={'mobile'}/>
              Roberto BAUTISTA AGUT (ESP)
              <span className={'versus'}>VS.</span><br className={'mobile'}/>
              MISCHA ZVEREV (GER)*
              <span className={'special-hint'}>*Ersatz für Tommy Haas wegen Verletzung</span>
            </li>

            <li>
            <span className={'special-hint'}>3.Platz Damen</span>
              <span className={'red'}>Followed by</span><br className={'mobile'}/>
              Andrea PETKOVIC (GER)
              <span className={'versus'}>VS.</span> <br className={'mobile'}/>
              Elina Svitolina (UKR)
            </li>

            <li>
            <span className={'special-hint'}>Finale Herren</span>
              <span className={'red'}>16 Uhr (CEST) </span><br className={'mobile'}/>
              Jannik SINNER (ITA)
              <span className={'versus'}>VS.</span> <br className={'mobile'}/>
              Dominic THIEM (AUT)
            </li>

            <li>
              <span className={'special-hint'}>Finale Damen</span>
              <span className={'red'}>Followed by</span><br className={'mobile'}/>
              Anastasija SEVASTOVA (LAT)
              <span className={'versus'}>VS.</span> <br className={'mobile'}/>
              PETRA KVITOVA (CZE)
            </li>
          </ul>

        </div>
      </StyledContainer>

      <Players/>
      <StyledContainer>
        <ColSection>
          <div className={'wp-something'}>
            <span className={'gray-sp'} id={'spielorte'}>SPIELORTE</span>
            <img src={imageT}/>
            <h2>
              <i className="i-class">FLUGHAFEN</i>
              <br></br>
              <i>TEMPELHOF</i>
            </h2>
            <h2 className="hidden-tittle"><i>STEFFI-GRAF-</i><br></br>
              <i className="steffi-i">STADION</i>
            </h2>
            <div className={'button-with-hover first-button'}>
              <a className={'button-red'} onClick={ (e) => {  e.preventDefault(); } } href="#"  rel="noopener noreferrer">
                <span className={'bt-n-act'}>Mehr erfahren</span><span className={'bt-no-act'}>Fenster schließen</span>
              </a>
              <div className={'homepage-pop-up pt-boti-cl'}>
                  <SocialMenu/>
                  <h5>FLUGHAFEN TEMPELHOF</h5>
                  <p>Der Flughafen Tempelhof in seiner heutigen Form wurde zwischen 1936 und 1941 gebaut. Als größtes Baudenkmal Europas ist der Flughafen durch die Luftbrücke 1948/1949 zu einem Symbol der Freiheit geworden. Bei den bett1ACES im Hangar 6 des Flughafens wird Tennis auf höchstem Niveau mit dem Urban Style des Flughafens kombiniert. Eine Location die es so zuvor noch nie im Tennissport gab.</p>
                  <span className={'span2'}></span>
                </div>


            <a className="hidden-link button-red" href="#" onClick={ (e) => { e.preventDefault();  } } target="_blank" rel="noopener noreferrer">
              <span className={'bt-n-act'}>Mehr erfahren</span><span className={'bt-no-act'}>Fenster schließen</span>
            </a>
                <div className={'homepage-pop-up pt-mobile pt-boti-cl'}>
                  <SocialMenu/>
                  <h5>STEFFI-GRAF-STADION</h5>
                  <p>Im Steffi-Graf-Stadion auf der Anlage des LTTC „Rot-Weiß“ e.V. wurden von 1979 bis 2008 die Internationalen Deutschen Meisterschaften ausgetragen. Anfang der 1980er Jahre konnten sich mit Bettina Bunge und Claudia Kohde-Kilsch zwei Deutsche in die Siegerlisten eintragen. Ab 1986 dominierte mit Steffi Graf eine weitere Deutsche das Turnier. Innerhalb von elf Jahren gewann sie das Turnier neun Mal und ist damit Rekordsiegerin der Veranstaltung. 2008 fand die Veranstaltung zum vorerst letzten Mal statt. 2020 wird das Stadion durch die bett1ACES wieder zum Leben erweckt. Mit großem Aufwand wurde in diesem Jahr ein Rasenbelag installiert.</p>
                  <span className={'span2'}></span>
                </div>
            </div>
          </div>
          <div className={'wp-something'}>
            <h2><i>STEFFI-GRAF-</i><br></br>
              <i class="steffi-i">STADION</i>
            </h2>
            <h2 className="hidden-tittle">
              <i>FLUGHAFEN</i>
              <br></br>
              <i className="i-class">TEMPELHOF</i>
            </h2>
            <div className={'button-with-hover second-button'}>
                <a className={'button-red'} onClick={ (e) => { e.preventDefault();  } } href="#" target="_blank" rel="noopener noreferrer">
                  <span className={'bt-n-act'}>Mehr erfahren</span><span className={'bt-no-act'}>Fenster schließen</span>
                </a>
                <div className={'homepage-pop-up pt-boti-cl'}>
                  <SocialMenu/>
                  <h5>STEFFI-GRAF-STADION</h5>
                  <p>Im Steffi-Graf-Stadion auf der Anlage des LTTC „Rot-Weiß“ e.V. wurden von 1979 bis 2008 die Internationalen Deutschen Meisterschaften ausgetragen. Anfang der 1980er Jahre konnten sich mit Bettina Bunge und Claudia Kohde-Kilsch zwei Deutsche in die Siegerlisten eintragen. Ab 1986 dominierte mit Steffi Graf eine weitere Deutsche das Turnier. Innerhalb von elf Jahren gewann sie das Turnier neun Mal und ist damit Rekordsiegerin der Veranstaltung. 2008 fand die Veranstaltung zum vorerst letzten Mal statt. 2020 wird das Stadion durch die bett1ACES wieder zum Leben erweckt. Mit großem Aufwand wurde in diesem Jahr ein Rasenbelag installiert.</p>
                  <span className={'span2'}></span>
                </div>
            </div>
            <div className={'button-with-hover pt-order'}>
              <a className="hidden-link button-red" onClick={ (e) => { e.preventDefault();   } }
                 href="#"
                 target="_blank"
                 rel="noopener noreferrer">
                <span className={'bt-n-act'}>Mehr erfahren</span><span className={'bt-no-act'}>Fenster schließen</span>
              </a>
                <div className={'homepage-pop-up pt-mobile pt-boti-cl'}>
                  <SocialMenu/>
                  <h5>FLUGHAFEN TEMPELHOF</h5>
                  <p>Der Flughafen Tempelhof in seiner heutigen Form wurde zwischen 1936 und 1941 gebaut. Als größtes Baudenkmal Europas ist der Flughafen durch die Luftbrücke 1948/1949 zu einem Symbol der Freiheit geworden. Bei den bett1ACES im Hangar 6 des Flughafens wird Tennis auf höchstem Niveau mit dem Urban Style des Flughafens kombiniert. Eine Location die es so zuvor noch nie im Tennissport gab.</p>
                  <span className={'span2'}></span>
                </div>
            </div>
            <img src={image}/>
          </div>
        </ColSection>
      </StyledContainer>
      <Turnir/>

      <ImageGallery/>
      <Partners/>
    </Wrapper>
  )
}

export default ContentHomepage
