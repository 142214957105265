import styled, { css } from 'styled-components'

import config from '../config'

const SubTitle = styled.h2`
  color: ${config.colors.lightGray01};
  font-weight: bold;
  font-style: italic;
  text-align: center;
  font-size: 26px;
  line-height: 1.15;
  text-transform: uppercase;
  margin: 0 0 20px 0;

  @media (max-width: 767px) {
    font-size: 18px;
    margin-bottom: 15px;
  }
`

export default SubTitle
