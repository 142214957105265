import styled, { css } from 'styled-components'

import config from '../config'

const Title = styled.h1`
  color: ${config.colors.black};
  font-weight: bold;
  font-style: italic;
  text-align: center;
  font-size: 40px;
  line-height: 1.05;
  text-transform: uppercase;
  margin: 0 0 86px 0;

  @media (max-width: 767px) {
    font-size: 26px;
    margin-bottom: 50px;
  }
`

export default Title
