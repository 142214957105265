import { css } from 'styled-components'

export default {
  colors: {
    black: '#2c2c2c',
    lightGray01: '#ececec',
    lightGray02: '#c6c6c6',
    red: '#e3051b',
    darkGray01: '#9d9d9d',
    darkGray02: '#575756',
    white: '#fff'
  },
}
