import React from 'react'


const ContentDatenschutz = () => (
  <>
    <h2>Verantwortlich im Sinne der Datenschutzgesetzes:</h2>
    <p>
      Firma: 360° rundum IT <br />
      Herr Gerhard Wersching <br />
      Kocherstrasse 12 <br />
      71065 Sindelfingen <br />
      Tel.: 07031 8195726 <br />
      <a href="mailto:info@360-rundum-IT.de">info@360-rundum-IT.de</a>
    </p>

    <h3>Erfassung von Daten</h3>
    <p>
    Während Sie auf unsere Webseiten zugreifen, erfassen wir automatisch Daten von allgemeiner Natur. Diese Daten (Server-Logfiles) umfassen zum Beispiel die Art ihres Browsers, ihr Betriebssystem, den Domainnamen Ihres Internetanbieters sowie weitere ähnliche allgemeine Daten. Diese Daten sind absolut Personen unabhängig und werden genutzt, um Ihnen die Webseiten korrekt darzustellen und werden bei jeder Nutzung des Internets abgerufen. Die absolut anonymen Daten werden statistisch ausgewertet um unseren Service für Sie zu verbessern.
    </p>

    <h3>Blogkommentare</h3>
    <p>
    Wenn Sie in unserem Blog Kommentare abgeben werden neben dem reinen Text auch Angaben zum Zeitpunkt der Erstellung sowie ihr Nutzername gespeichert. Auf diese Art garantieren wir die Sicherheit unseres Blog, rechtswiedrige Beiträge von Benutzern können nach verfolgt werden.
    </p>

    <h3>Newsletter</h3>
    <p>
    Mit den nachfolgenden Hinweisen informieren wir Sie über die Inhalte unseres Newsletters sowie das Anmelde-, Versand- und das statistische Auswertungsverfahren sowie Ihre Widerspruchsrechte auf. Indem Sie unseren Newsletter abonnieren, erklären Sie sich mit dem Empfang und den beschriebenen Verfahren einverstanden.
    </p>
    <p>
    Inhalt des Newsletters: Wir versenden Newsletter, E-Mails und weitere elektronische Benachrichtigungen mit werblichen Informationen (nachfolgend „Newsletter“) nur mit der Einwilligung der Empfänger oder einer gesetzlichen Erlaubnis. Sofern im Rahmen einer Anmeldung zum Newsletter dessen Inhalte konkret umschrieben werden, sind sie für die Einwilligung der Nutzer maßgeblich. Im Übrigen enthalten unsere Newsletter Informationen zu unseren Leistungen und uns.
    </p>
    <p>
    Double-Opt-In und Protokollierung: Die Anmeldung zu unserem Newsletter erfolgt in einem sog. Double-Opt-In-Verfahren. D.h. Sie erhalten nach der Anmeldung eine E-Mail, in der Sie um die Bestätigung Ihrer Anmeldung gebeten werden. Diese Bestätigung ist notwendig, damit sich niemand mit fremden E-Mailadressen anmelden kann. Die Anmeldungen zum Newsletter werden protokolliert, um den Anmeldeprozess entsprechend den rechtlichen Anforderungen nachweisen zu können. Hierzu gehört die Speicherung des Anmelde- und des Bestätigungszeitpunkts, als auch der IP-Adresse. Ebenso werden die Änderungen Ihrer bei dem Versanddienstleister gespeicherten Daten protokolliert.
    </p>
    <p>
    Anmeldedaten: Um sich für den Newsletter anzumelden, reicht es aus, wenn Sie Ihre E-Mailadresse angeben. Optional bitten wir Sie einen Namen, zwecks persönlicher Ansprache im Newsletters anzugeben.
    </p>
    <p>
    Der Versand des Newsletters und die mit ihm verbundene Erfolgsmessung erfolgen auf Grundlage einer Einwilligung der Empfänger gem. Art. 6 Abs. 1 lit. a, Art. 7 DSGVO i.V.m § 7 Abs. 2 Nr. 3 UWG oder falls eine Einwilligung nicht erforderlich ist, auf Grundlage unserer berechtigten Interessen am Direktmarketing gem. Art. 6 Abs. 1 lt. f. DSGVO i.V.m. § 7 Abs. 3 UWG.
    </p>
    <p>
    Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer berechtigten Interessen gem. Art. 6 Abs. 1 lit. f DSGVO. Unser Interesse richtet sich auf den Einsatz eines nutzerfreundlichen sowie sicheren Newslettersystems, das sowohl unseren geschäftlichen Interessen dient, als auch den Erwartungen der Nutzer entspricht und uns ferner den Nachweis von Einwilligungen erlaubt.
    </p>
    <p>
    Kündigung/Widerruf – Sie können den Empfang unseres Newsletters jederzeit kündigen, d.h. Ihre Einwilligungen widerrufen. Einen Link zur Kündigung des Newsletters finden Sie am Ende eines jeden Newsletters. Wir können die ausgetragenen E-Mailadressen bis zu drei Jahren auf Grundlage unserer berechtigten Interessen speichern bevor wir sie löschen, um eine ehemals gegebene Einwilligung nachweisen zu können. Die Verarbeitung dieser Daten wird auf den Zweck einer möglichen Abwehr von Ansprüchen beschränkt. Ein individueller Löschungsantrag ist jederzeit möglich, sofern zugleich das ehemalige Bestehen einer Einwilligung bestätigt wird.
    </p>
    <h3>Newsletter – Mailchimp</h3>
    <p>
    Der Versand der Newsletter erfolgt mittels des Versanddienstleisters „MailChimp“, einer Newsletterversandplattform des US-Anbieters Rocket Science Group, LLC, 675 Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA. Die Datenschutzbestimmungen des Versanddienstleisters können Sie hier einsehen: <a href="https://mailchimp.com/legal/privacy/" target="_blank"
    rel="noopener noreferrer">https://mailchimp.com/legal/privacy</a>. The Rocket Science Group LLC d/b/a MailChimp ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäisches Datenschutzniveau einzuhalten (<a href="https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&status=Active" target="_blank"
    rel="noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&status=Active</a>). Der Versanddienstleister wird auf Grundlage unserer berechtigten Interessen gem. Art. 6 Abs. 1 lit. f. DSGVO und eines Auftragsverarbeitungsvertrages gem. Art. 28 Abs. 3 S. 1 DSGVO eingesetzt.
      </p>
      <p>
    Der Versanddienstleister kann die Daten der Empfänger in pseudonymer Form, d.h. ohne Zuordnung zu einem Nutzer, zur Optimierung oder Verbesserung der eigenen Services nutzen, z.B. zur technischen Optimierung des Versandes und der Darstellung der Newsletter oder für statistische Zwecke verwenden. Der Versanddienstleister nutzt die Daten unserer Newsletterempfänger jedoch nicht, um diese selbst anzuschreiben oder um die Daten an Dritte weiterzugeben.
    </p>
    <h3>Kontaktformular</h3>
    <p>
    Wenn Sie uns über unsere Kontaktseite kontaktieren oder uns eine E-Mail schicken werden die entsprechenden Daten zur Bearbeitung gespeichert.
    </p>
    <h3>Löschung oder Sperrung von Daten</h3>
    <p>
    Ihre persönlichen Daten werden nur so lange gespeichert, wie dies absolut notwendig ist um die angegebenen Dienste zu leisten und es vom Gesetz vorgeschrieben ist. Nach Ablauf dieser Fristen werden die persönlichen Daten der Nutzer regelmäßig gesperrt oder gelöscht.
    </p>
    <h3>Google Analytics</h3>
    <p>
    Unsere Webseite nutzt Google Analytics, den Webanalysedienst der Google Inc. („Google“). Google Analytics nutzt „Cookies“, kleine Textdateien, die auf Ihrem Rechner gespeichert werden und Daten zur Nutzung unserer Webseite speichern. Die vom Cookie erzeugten Daten über die Nutzung unserer Webseite werden an einen Google Server in die USA übertragen und gespeichert. Da auf unseren Webseiten eine IP-Anonymisierung aktiviert ist, wird Ihre IP-Adresse von Google gekürzt. Dies gilt für Mitgliedstaaten der Europäischen Union und anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum. In seltenen Fällen kann es vorkommen, dass die volle IP-Adresse an einen Google Server in den USA übertragen und erst dort gekürzt wird. Google nutzt diese Informationen um Ihre Nutzung unserer Webseite auszuwerten, um für uns Berichte über die Nutzung der Webseite zusammenzustellen und um weitere statistische Daten zu erheben. Ihre IP-Adresse wird dabei niemals mit anderen auf Google gespeicherten Daten zusammengeführt. Die Verwendung von Cookies kann durch eine Einstellung in ihrem Browser verboten werden; dies kann jedoch unter Umständen die Funktion unserer Webseite beeinträchtigen. Mittels eines speziellen Zusatzprogramms für ihren Browser (Add On) können Sie die Sammlung von Daten durch Google Analytics desaktivieren.
    </p>
    <h3>Social Plugins</h3>
    <p>Wir verwenden auf unserer Webseiten Social Plugins der im Folgenden aufgelisteten Anbieter. Plugins sind mit dem entsprechenden Logo gekennzeichnet.
      Der Dienstbetreiber kann unter Umständen durch solche Plugins Informationen und personenbezogene Daten unserer Besucher erhalten. Eine von uns installierte 2-Klick-Lösung verhindert die nicht offensichtliche Erfassung und Übertragung von Daten an den Dienstanbieter. Nur wenn ein Social Plugin von Ihnen per Klick aktiviert wird, wird die Sammlung von Informationen und deren Versand an den Dienstanbieter ausgelöst.
      Welche Daten ein solches Plugin erfasst sobald es aktiviert wurde, und wie diese anschließend verwendet werden, liegt außerhalb unseres Einflusses. In einigen Fällen werden direkte Verbindungen zu den Diensten des jeweiligen Anbieters erstellt und die IP-Adresse und spezifische Nutzungs-Informationen können dabei erfasst und genutzt werden. Dienstanbieter können auch versuchen, Cookies auf ihrem Rechner zu speichern. Wie diese Cookies arbeiten erfahren Sie in den Datenschutzhinweisen des entsprechenden Anbieters. Von Facebook können Sie als Besucher identifiziert werden, sofern sie aktuell in ihrem Facebook Account angemeldet sind.
      Social-Media-Buttons der folgenden Unternehmen sind auf unserer Webseite eingebunden:</p>
    <p>
      Facebook<br></br>
      Twitter<br></br>
      Instagram<br></br>
      Youtube
    </p>
    <p>Link Buttons im Footer Linken nur zu unseren Social Media Kanälen.</p>
    <h3>Cookies</h3>
    <p>Unsere Webseite verwendet „Cookies“. Cookies sind Textdateien, die vom Server einer Webseite auf Ihren Rechner übertragen werden. Bestimmte Daten wie IP-Adresse, Browser, Betriebssystem und Internet Verbindung werden dabei übertragen.
      Cookies starten keine Programme und übertragen keine Viren. Die durch Cookies gesammelten Informationen dienen dazu, Ihnen die Navigation zu erleichtern und die Anzeige unserer Webseiten zu optimieren.
      Daten, die von uns erfasst werden, werden niemals ohne Ihre Einwilligung an Daten an Dritte weitergegeben oder mit personenbezogenen Daten verknüpft.
      Die Verwendung von Cookies kann über Einstellungen in ihrem Browser verhindert werden. In den Erläuterungen zu Ihrem Internetbrowsers finden Sie Informationen darüber, wie man diese Einstellungen verändern kann. Einzelne Funktionen unserer Website können unter Umständen nicht richtig funktionieren, wenn die Verwendung von Cookies deaktiviert ist.
      Wir verwenden das Cookie Consent Verfahren (Speichern von Cookies erst nach Zustimmung). Die Einstellungen dazu finden Sie am Ende der Datenschutzerklärung.
    </p>
    <p></p>
  </>
)

export default ContentDatenschutz
