import React, {useState} from 'react'
import styled, { css } from 'styled-components'
import scrollTo from 'gatsby-plugin-smoothscroll';

import config from '../config'

const Wrapper = styled.ul`
  flex: 1 0 auto;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Item = styled.li`
  display: block;
  position: relative;
  padding: 0 4px;
  margin: 0 40px 0 0;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  cursor : pointer;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #2c2c2c; 
  transition : all 0.3s ease-in-out;
  
  &:hover, &.active{
    color : #e3051b;
  }
  
   @media (max-width: 1600px) {
    margin: 0 25px 0 0; 
  }
   @media (max-width: 1220px) {
   margin: 0 2px 0 0; 
  }
  @media (max-width: 1024px) {
   display: none;
  }
  &:last-child {
    margin-right: 118px;
    @media (max-width: 1600px) {
    margin-right: 45px;
    }
    @media (max-width: 1220px) {
   margin-right: 10px; 
  }
  }
  a{
    color: ${config.colors.black};
    font-size: 14px;
    line-height: 1.43;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    &:hover{
      color: ${config.colors.red};
    }
  }
`
const Link = styled.a`
  display: inline-block;
  margin-right: 118px;
  @media (max-width: 1600px) {
    margin-right: 45px; 
  }
  @media (max-width: 1200px) {
   margin-right: 10px; 
  }
`
function NavigationList({ children, setNavbarOpen, activeItem }) {

    const closeNavBare = (name) => {
        setNavbarOpen(false);
    }
    return (
        <Wrapper>
            <Link
                href="#"
                target="_blank"
                rel="noopener noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="168" height="41" viewBox="0 0 168 41">
                    <g fill="none" fillRule="evenodd">
                        <path fill="#FFF" d="M149.6.682H.46v27.967c-.007 5.923 4.815 10.724 10.804 10.724H167.54V18.421c0-9.797-8.032-17.74-17.94-17.74"/>
                        <path fill="#E3051B" d="M0 .227V28.85c-.006 6.062 4.928 10.976 11.058 10.976h61.797c6.13 0 11.135-4.914 11.14-10.976V.227H0z"/>
                        <path fill="#FFF" d="M22.5 18.76c-1.196 0-2.04 1.048-2.04 2.793 0 2.095.824 2.794 2.1 2.794 1.176 0 1.96-.95 1.96-2.6 0-2.153-.725-2.987-2.02-2.987m-.176 8.38c-3.688 0-5.492-1.998-5.492-5.14v-9.505h.902c2.06 0 2.707.601 2.707 2.327v2.503c.608-.776 1.275-1.455 2.746-1.455 2.942 0 5.06 2.017 5.06 5.49 0 3.568-2.47 5.78-5.923 5.78M35.083 18.63c-1.314 0-1.863.833-1.961 1.861h1.45c1.766 0 2.08-.271 2.08-.737 0-.543-.49-1.125-1.57-1.125m-.254 4.229H33.22c.215 1.067 1.098 1.571 2.647 1.571 1.726 0 2.806-.272 3.414-.679h.137v.854c0 1.357-1.118 2.58-4.198 2.58-3.726 0-5.688-2.483-5.688-5.432 0-3.24 2.216-5.877 5.55-5.877 2.826 0 5.022 1.571 5.022 4.287 0 2.133-1.628 2.696-5.276 2.696M43.028 14.482c.49-.368 1.158-.717 1.942-.717h.393v2.6h3.158v1.299c0 .911-.981 1.61-1.825 1.61h-1.333v3.86c0 .717.45 1.144 1.177 1.144.882 0 1.294-.078 1.687-.233h.097v1.436c0 .892-.823 1.707-2.765 1.707-1.844 0-3.825-.873-3.825-3.802V16.77c0-.97.569-1.745 1.294-2.289M51.542 14.482c.49-.368 1.157-.717 1.941-.717h.393v2.6h3.158v1.299c0 .911-.98 1.61-1.824 1.61h-1.334v3.86c0 .717.45 1.144 1.177 1.144.882 0 1.294-.078 1.687-.233h.098v1.436c0 .892-.824 1.707-2.766 1.707-1.844 0-3.825-.873-3.825-3.802V16.77c0-.97.57-1.745 1.295-2.289M64.305 27.19c-1.118 0-1.98-.756-1.98-2.037v-7.68l-1.237.387c-.274.078-.549.117-.804.117-.785 0-1.393-.369-1.51-1.01l-.236-1.28 3.55-1.473c.59-.253 1.335-.408 2.178-.408.883 0 1.785.62 1.785 1.668V27.19h-1.746z"/>
                        <path fill="#E3051B" d="M101.351 23.953h3.988v-7.712l-3.988 7.712zm3.988 6.31v-3.17h-5.62l-1.647 3.17h-3.604l10.387-19.982h3.945l-.023 19.981h-3.438zM115.481 30.49c-1.671 0-2.958-.39-3.859-1.17-.954-.951-1.237-2.198-.848-3.74l2.676-10.618c.389-1.542 1.3-2.788 2.733-3.739 1.295-.78 2.779-1.17 4.45-1.17 1.719 0 3.005.39 3.859 1.17.963.913 1.246 2.16.848 3.739l-.446 1.77h-3.94l.447-1.77c.177-.704.13-1.18-.142-1.427-.253-.229-.726-.343-1.418-.343-.668 0-1.187.114-1.554.343-.426.267-.724.742-.897 1.427l-2.677 10.619c-.172.685-.113 1.16.178 1.427.252.229.713.342 1.382.342.692 0 1.222-.113 1.59-.342.396-.247.684-.723.861-1.427l.648-2.569h3.939l-.647 2.569c-.398 1.58-1.31 2.826-2.733 3.739-1.248.78-2.731 1.17-4.45 1.17M138.701 13.42L132.254 13.42 130.988 18.445 135.88 18.445 135.088 21.585 130.196 21.585 128.801 27.123 135.247 27.123 134.456 30.262 124.07 30.262 129.106 10.281 139.492 10.281zM142.11 30.49c-1.718 0-3.005-.39-3.859-1.17-.964-.913-1.246-2.16-.848-3.74l.655-2.596h3.94l-.656 2.597c-.172.685-.113 1.16.178 1.427.252.229.713.342 1.382.342.692 0 1.21-.113 1.554-.342.42-.247.72-.723.897-1.427l.317-1.256c.22-.875-.03-1.637-.75-2.284-.157-.133-.596-.38-1.316-.742-1.003-.476-1.721-.894-2.155-1.256-.706-.61-1.188-1.304-1.444-2.084-.257-.78-.273-1.616-.048-2.512l.123-.485c.398-1.58 1.308-2.826 2.733-3.739 1.247-.78 2.73-1.17 4.449-1.17 1.719 0 3.005.39 3.86 1.17.962.913 1.245 2.16.847 3.739l-.439 1.741h-3.939l.439-1.741c.177-.704.118-1.18-.178-1.427-.228-.229-.69-.343-1.381-.343-.67 0-1.187.114-1.555.343-.426.267-.724.742-.897 1.427l-.123.485c-.22.876.032 1.627.757 2.255.2.153.608.381 1.224.685 1.107.533 1.856.971 2.248 1.313 1.45 1.256 1.945 2.798 1.484 4.625l-.316 1.256c-.398 1.58-1.31 2.826-2.733 3.739-1.248.78-2.73 1.17-4.45 1.17"/>
                        <path fill="#E3051B" d="M.92 1.136V28.65c-.004 2.753 1.074 5.336 3.033 7.276 1.95 1.93 4.547 2.994 7.312 2.994H167.08V18.42c0-4.624-1.798-8.966-5.064-12.224-3.271-3.263-7.64-5.06-12.3-5.06H.918zM168 39.828H11.264c-3.01 0-5.838-1.158-7.962-3.26C1.17 34.455-.003 31.642 0 28.647V.228h149.715c4.909 0 9.509 1.892 12.954 5.33 3.437 3.429 5.33 7.997 5.33 12.863v21.407z"/>
                    </g>
                </svg>
            </Link>
            <Item onClick={() => window.open('https://www.eventimsports.de/ols/bett1aces/', '_blank') }>TICKETS</Item>
            <Item className={activeItem == 'players' ? 'nav-menu-pt active' : 'nav-menu-pt'} id={'players-l'} onClick={() => { closeNavBare('players'); scrollTo('#players') } }>SPIELER/INNEN</Item>
            <Item className={activeItem == 'spielorte' ? 'nav-menu-pt active' : 'nav-menu-pt'} id={'spielorte-l'} onClick={() => { closeNavBare('spielorte'); scrollTo('#spielorte')} }>SPIELORTE</Item>
            <Item className={activeItem == 'turnier' ? 'nav-menu-pt active' : 'nav-menu-pt'} id={'turnier-l'} onClick={() => { closeNavBare('turnier'); scrollTo('#turnier')} }>TURNIER</Item>
            <Item className={activeItem == 'galerie' ? 'nav-menu-pt active' : 'nav-menu-pt'} id={'galerie-l'} onClick={() => { closeNavBare('galerie'); scrollTo('#galerie')} }>GALERIE</Item>
            <Item className={activeItem == 'partner' ? 'nav-menu-pt active' : 'nav-menu-pt'} id={'partner-l'} onClick={() => { closeNavBare('partner'); scrollTo('#partner')} }>PARTNER</Item>
        </Wrapper>
    )
}

export default NavigationList
